import Api from '../../store/api'
import { QUOTE_ACCEPT, QUOTE_ATTACH_FILE, QUOTE_INFOS, QUOTE_REJECT } from "../../store/endpoints"

export const fetch_quote_infos = (uuid, failed) => {
    return function (dispatch) {
        let url = `${QUOTE_INFOS}${uuid}/`
        Api('get', url).then(response => {
            console.log(response)
            if(response && response.status === 200) {
                dispatch({ type: 'UPDATE_QUOTE_INFO', quote: response.data })
                dispatch({ type: 'UPDATE_UUID', uuid: uuid })
                dispatch({ type: 'UPDATE_SELECTED_QUOTE', quote: response.data.template, id: response.data.id })
                dispatch({ type: 'UPDATE_CLIENT', client : response.data.client })
                
                
                let object_template = {
                    id: response.data.id,
                    template: response.data.template,
                    template_name: "main_quote"
                }

                let attached_templates = [object_template]
                response.data.attached_files && response.data.attached_files.map((item, key) => {
                    attached_templates.push(
                        {
                            id: item.id,
                            template: item.template_html,
                            template_name: item.name  
                        }
                    )
                    return null
                })
                console.log(attached_templates, 'attached_templates')
                dispatch({ type: 'UPDATE_TEMPLATE', templates: attached_templates })
                dispatch({ type: 'SET_OLD_TEMPLATES', templates: attached_templates})
                
            } else {
                dispatch({ type: 'UPDATE_QUOTE_INFO', quote: {} })
                dispatch({ type: 'UPDATE_UUID', uuid: '' })
                failed()
            }
        })
    }
}

export const refreshTemplateList = (temparr) =>{
    return function (dispatch){
        dispatch({
            type : 'UPDATE_TEMPLATE',
            templates: temparr
        })
    }
}

export const reject_quote = (uuid, success, failed) => {
    return function (dispatch) {
        let url = `${QUOTE_REJECT}${uuid}/`
        Api('get', url).then(response => {
            const { message, status } = response
            if(status === 200) {
                success(message)
                dispatch({ type: 'UPDATE_MESSAGE', message: message})
            } else {
                failed(message)
                dispatch({ type: 'UPDATE_MESSAGE', message: message})
            }
            
        })
    }
}


export const accept_quote = (uuid, data, success, failed) => {
    return function (dispatch) {
        for(let v of data.entries()){
            console.log('accept_quote',v[0],v[1])
        }
        let url = `${QUOTE_ACCEPT}${uuid}/`
        Api('post-form', url, data).then(response => {
            const { message, status } = response
            if(status === 200) {
                dispatch({ type: 'UPDATE_MESSAGE', message: message})
                success(message)
            } else {
                dispatch({ type: 'UPDATE_MESSAGE', message: message})
                failed(message)
            }
            
        })
    }
}



export const quote_response = (uuid, data, success=null, failed=null) => {
    return function (dispatch) {
        let url = `${QUOTE_ATTACH_FILE}${uuid}/`
        Api('post-form', url, data).then(response => {
            console.log(response)
            if(response && response.status===200 && success!==null)
                success()
            else if(failed!==null)
                failed()
        })
    }
}