import React, {useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import DateTimeInput from '../common/DateTimeInput';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import dayjs from 'dayjs';
import SignaturePadModal from '../common/SignaturePadModal';

function FormModal ({modalOpen, setModalOpen, editAction, templateName, client}) {

    const [custName, setCustName]       = useState(client ? client.name : "")
    const [entityName, setEntityName]   = useState(client && client.company_name ? client.company_name : "")
    const [entityAddr, setEntityAddr]   = useState(client && client.company_address ? client.company_address : "")
    const [entityPos, setEntityPos]     = useState(client && client.company_postcode ? client.company_postcode : "")
    const [abn, setAbn]                 = useState(client && client.abn ? client.abn : "")
    const [accConName, setAccConName]   = useState("")
    const [accConPh, setAccConPh]       = useState("")
    const [accConEmail, setAccConEmail] = useState("")
    const [payType, setPayType]         = useState("CREDIT CARD")
    const [purchOrdReq, setPurchOrdReq] = useState("")
    const [cardType, setCardType]   = useState(false)
    
    const [cardNo1, setCardNo1]     = useState("")
    const [cardNo2, setCardNo2]     = useState("")
    const [cardNo3, setCardNo3]     = useState("")
    const [cardNo4, setCardNo4]     = useState("")
    const [cardNo5, setCardNo5]     = useState("")
    const [cardNo6, setCardNo6]     = useState("")
    const [cardNo7, setCardNo7]     = useState("")
    const [cardNo8, setCardNo8]     = useState("")
    const [cardNo9, setCardNo9]     = useState("")
    const [cardNo10, setCardNo10]     = useState("")
    const [cardNo11, setCardNo11]     = useState("")
    const [cardNo12, setCardNo12]     = useState("")
    const [cardNo13, setCardNo13]     = useState("")
    const [cardNo14, setCardNo14]     = useState("")
    const [cardNo15, setCardNo15]     = useState("")
    const [cardNo16, setCardNo16]     = useState("")
    
    const [expDate, setExpDate]   = useState(new Date())
    const [cardIdNo1, setCardIdNo1] = useState("")
    const [cardIdNo2, setCardIdNo2] = useState("")
    const [cardIdNo3, setCardIdNo3] = useState("")
    const [amt, setAmt]           = useState("")
    const [cardName, setCardName] = useState("")
    const [serviceProv, setServiceProv] = useState("")
    const [printName, setPrintName] = useState("")
    const [sign, setSign]         = useState("")
    const [signDate, setSignDate] = useState("")
    const [openSign, setOpenSign] = useState(false)

    const signFunc = (val) => {
        setSign(val)
    }

    const updateTemplateCode = () =>{

        let tempObj = {
            'credit_card_auth_customer_name' : custName,
            'credit_card_auth_entity_name'          : entityName,
            'credit_card_auth_entity_address'       : entityAddr,
            'credit_card_auth_entity_postcode'      : entityPos,
            'credit_card_auth_abn'                  : abn,
            'credit_card_auth_account_contact_name' : accConName,
            'credit_card_auth_account_contact_phone': accConPh,
            'credit_card_auth_account_contact_email': accConEmail,
            'credit_card_auth_payment_type'         : payType,
            'credit_card_auth_purchase_order'       : purchOrdReq,

            'credit_card_auth_card_type'     :   cardType ? 'Master Card' : 'Visa Card',
            'credit_card_auth_card_number'   :   `${cardNo1}${cardNo2}${cardNo3}${cardNo4}${cardNo5}${cardNo6}${cardNo7}${cardNo8}${cardNo9}${cardNo10}${cardNo11}${cardNo12}${cardNo13}${cardNo14}${cardNo15}${cardNo16}`,
            'credit_card_auth_expiry_date'   :   expDate,
            'credit_card_auth_card_id_number':   `${cardIdNo1}${cardIdNo2}${cardIdNo3}`,
            'credit_card_auth_amount'        :   amt,
            'credit_card_auth_name_on_card'  :   cardName,
            'credit_card_auth_service_provided' : serviceProv,
            'credit_card_auth_print_name'    :   printName,
            'credit_card_auth_signature'     :   sign,
            'credit_card_auth_date'          :   signDate
        }

        let str = `<html>
        <head>
    
        </head>
        <body style="padding: 10px 10px;">
            <table style="width: 100%;">
                <tbody style="width: 100%;">
                    <tr style="width: 100%;">
                        <td>
                            <span> <img width="100px" height="auto" src="https://enviro-drive.s3.amazonaws.com/staging/media/uploads/drive/file-archive/My_project.png" alt=""></span>
                        </td>
                        <td style="text-align: center;">
            <p style="font-size: 18px;font-weight: 850;">CREDIT CARD AUTHORISATION FORM</p>
                            
                        </td>
                        <td >
                            
                            <p style="color: rgb(50, 51, 126); font-size: 10.5px; text-align: right;"> 
                                Lic# 13039- EPA Lic# 20444 -ABN 66 613 987 438 <br>
                                PO BOX 4392 North Rocks NSW 2151 <br>
                                Phone: 1300 141 315 <br>
                                Email: info@envirowaste.com.au <br>
                                www.envirowaste.com.au <br>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 8pt;"><strong id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><span style="font-size: 9pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">All fields are to be completed.&nbsp;You&nbsp;may&nbsp;cancel&nbsp;this&nbsp;authorization&nbsp;at&nbsp;any&nbsp;time by contacting us in writing. This authorization will remain in effect until cancelled. I understand that my information will be saved for future transaction.</span></strong></p>
                        <p><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><br>
                            </b></p>
                        <p dir="ltr" style="line-height: 1.2; margin-left: 7.1pt; margin-top: 12pt; margin-bottom: 12pt;"><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I, ${custName ? custName : ` _______`}, hereby give </span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Enviro Waste services Group Pty Ltd</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> the&nbsp;</span></b></p>
                        <p><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;">
                            </b></p>
                        <p dir="ltr" style="line-height: 1.2; margin-left: 7.1pt; margin-top: 12pt; margin-bottom: 12pt;"><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">authorisation to charge the amount listed below to the credit card provided herein, prior to services being provided.&nbsp;&nbsp;</span></b></p>
                        <p><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;">
                                
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Card Details:&nbsp;</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company Name:&nbsp; &nbsp; &nbsp; ${ entityName ? entityName : `________________________________________________________`}</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company address:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> &nbsp; ${ entityAddr ? entityAddr : `________________________________________________________`}</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company Postcode:&nbsp; ${entityPos ? entityPos : `________________________________________________________`}</span></p><br>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">ABN:&nbsp; &nbsp; &nbsp; ${ abn ? abn : `________________________________________________________`}</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;&nbsp;</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Name:&nbsp; &nbsp; &nbsp; &nbsp; ${ accConName ? accConName : `________________________________________________________`}</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;&nbsp;</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Phone:&nbsp; &nbsp; &nbsp; &nbsp; ${ accConPh ? accConPh : `________________________________________________________`}</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;&nbsp;</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Email:&nbsp; &nbsp; &nbsp; &nbsp; ${ accConEmail ? accConEmail : `________________________________________________________`}</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Payment Type:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;${ payType==='CREDIT CARD' ? 'Credit Card' : payType==='ACCOUNT' ? 'Account' : 'Pre Paid Cash' } </span></p><br>
                        
                        <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Purchase Order Required:&nbsp; &nbsp; &nbsp; &nbsp; ${ purchOrdReq ? purchOrdReq : `________________________________________________________`}</span>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Please Select:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${!cardType ? `&#x2610;`:`&#x2611;`}MasterCard &nbsp;&nbsp;&nbsp;&nbsp; ${cardType ? `&#x2610;`:`&#x2611;`}Visa Card</span></p><br>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Card Number (16 digits): </span><span style="font-size: 16pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> ${ cardNo1 ? cardNo1 :`__`},  ${ cardNo2 ? cardNo2 :`__`},  ${ cardNo3 ? cardNo3 :`__`},  ${ cardNo4 ? cardNo4 :`__`},  ${ cardNo5 ? cardNo5 :`__`},  ${ cardNo6 ? cardNo6 :`__`},  ${ cardNo7 ? cardNo7 :`__`},  ${ cardNo8 ? cardNo8 :`__`},  ${ cardNo9 ? cardNo9 :`__`},  ${ cardNo10 ? cardNo10 :`__`},  ${ cardNo11 ? cardNo11 :`__`},  ${ cardNo12 ? cardNo12 :`__`},  ${ cardNo13 ? cardNo13 :`__`},  ${ cardNo14 ? cardNo14 :`__`},  ${ cardNo15 ? cardNo15 :`__`},  ${ cardNo16 ? cardNo16 :`__`},</span></p>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Expiry Date: (mm/year)&nbsp;  ${ dayjs(expDate).isValid() ? dayjs(expDate).format('MM/YYYY') : `__ __`}&nbsp;</span></p>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Card Identification Number:  ${ cardIdNo1 ? cardIdNo1 :`__`},  ${ cardIdNo2 ? cardIdNo2 :`__`},  ${ cardIdNo3 ? cardIdNo3 :`__`} </span><span style="font-size: 9pt; font-family: &quot;Century Gothic&quot;, sans-serif; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(last 3 digits located on the back of the credit card)</span></p><br>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Amount to Charge:&nbsp; $&nbsp;  ${ amt ? amt : `________`} (AUD, Ex GST)&nbsp;</span></p><br>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name on the Card:&nbsp; ${ cardName ? cardName :`________`}</span></p><br>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Service Provided (Summarized): ${ serviceProv ? serviceProv :`________`}</span></p><br>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${ serviceProv ? null :`_________________________________________________________________________`}</span></p><br>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Authorized Cardholder – Please Sign and Date</span></p><br>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 6pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Print Name: </span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${ printName ? printName :`________`}</span></p>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 6pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Signature:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
                                ${ sign ? `<img src=${sign} style="margin-left:100px;margin-top:0px;" width="200" height="100">` 
                                    :`________`}</span></p>
                                <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 6pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Date:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${ dayjs(signDate).isValid() ? dayjs(signDate).format('DD/MM/YYYY') :`________`}</span></p>
                            </b><br class="Apple-interchange-newline" style="font-style: normal; font-variant-caps: normal; font-weight: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0);"></p>
                        <p></p>
                        <p></p>
                        <p><br></p>
        </body>
    </html>${ custName!=='' ||  entityName!=='' || abn!=='' || entityAddr!=='' || entityPos!=='' || purchOrdReq!=='' ||  cardNo1!=='' || cardNo2!=='' || cardNo3!=='' || cardNo4!=='' || cardNo5!=='' || cardNo6!=='' || cardNo7!=='' || cardNo8!=='' || cardNo9!=='' || cardNo10!=='' || cardNo11!=='' || cardNo12!=='' || cardNo13!=='' || cardNo14!=='' || cardNo15!=='' || cardNo16!=='' || 
        cardIdNo1!=='' || cardIdNo2!=='' || cardIdNo3!=='' || amt!=='' || cardName!=='' || serviceProv!=='' || printName!=='' || sign!=='' || signDate!=='' 
            ? `<!--DATAENTERED-->` : ``} `
        editAction(str,tempObj,templateName)
        // setTemplateStr(str)
        setModalOpen(false)
    }

    return (
        <div className={ modalOpen ? "quoteModalCloseBx folderModalBg" : "folderModalBg"}>
        <form className="folderModalContent">
        <HighlightOffRoundedIcon style={{ fontSize:'25px', color:'blue', float:'right' }} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); setModalOpen(false);}}/>

            <Scrollbars style={{ height:'65vh' }}> 
            <div style={{ display:'flex', flexDirection:'column', padding:'20px' }}>
<p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '8pt'}}>
    <strong id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style={{fontStyle: 'normal',
fontVariantCaps: 'normal',
letterSpacing: 'normal',
orphans: 'auto',
textAlign: 'start',
textIndent: '0px',
textTransform: 'none',
whiteSpace: 'normal',
widows: 'auto',
wordSpacing: '0px',
webkitTextSizeAdjust: 'auto',
webkitTextStrokeWidth: '0px',
textDecoration: 'none',
caretColor: 'rgb(0 0 0)',
color: 'rgb(0 0 0)',
fontWeight: 'normal'}}>
        <span style={{fontSize: '9pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
            All fields are to be completed.&nbsp;You&nbsp;may&nbsp;cancel&nbsp;this&nbsp;authorization&nbsp;at&nbsp;any&nbsp;time by contacting us in writing. This authorization will remain in effect until cancelled. I understand that my information will be saved for future transaction.
        </span>
    </strong></p>
<p><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style={{fontStyle: 'normal',
fontVariantCaps: 'normal',
letterSpacing: 'normal',
orphans: 'auto',
textAlign: 'start',
textIndent: '0px',
textTransform: 'none',
whiteSpace: 'normal',
widows: 'auto',
wordSpacing: '0px',
webkitTextSizeAdjust: 'auto',
webkitTextStrokeWidth: '0px',
textDecoration: 'none',
caretColor: 'rgb(0 0 0)',
color: 'rgb(0 0 0)',
fontWeight: 'normal'}}><br/>
    </b></p>
<p dir="ltr" style={{lineHeight: '1.2', marginLeft: '7.1pt', marginTop: '12pt', marginBottom: '12pt'}}>
    <b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style={{fontStyle: 'normal',
fontVariantCaps: 'normal',
letterSpacing: 'normal',
orphans: 'auto',
textAlign: 'start',
textIndent: '0px',
textTransform: 'none',
whiteSpace: 'normal',
widows: 'auto',
wordSpacing: '0px',
webkitTextSizeAdjust: 'auto',
webkitTextStrokeWidth: '0px',
textDecoration: 'none',
caretColor: 'rgb(0 0 0)',
color: 'rgb(0 0 0)',
fontWeight: 'normal'}}>
    <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
        I, 
        <input type="text" value={custName} onChange={(e) => setCustName(e.target.value)}/>
        , hereby give 
    </span>
    <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
        Enviro Waste services Group Pty Ltd
    </span>
    <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
         the&nbsp;
    </span>
    </b>
</p>
<p><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style={{fontStyle: 'normal',
fontVariantCaps: 'normal',
letterSpacing: 'normal',
orphans: 'auto',
textAlign: 'start',
textIndent: '0px',
textTransform: 'none',
whiteSpace: 'normal',
widows: 'auto',
wordSpacing: '0px',
webkitTextSizeAdjust: 'auto',
webkitTextStrokeWidth: '0px',
textDecoration: 'none',
caretColor: 'rgb(0 0 0)',
color: 'rgb(0 0 0)',
fontWeight: 'normal'}}>
        <p dir="ltr" style={{lineHeight: '1.2', marginLeft: '7.1pt', marginTop: '12pt', marginBottom: '12pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                authorisation to charge the amount listed below to the credit card provided herein, prior to services being provided.&nbsp;&nbsp;
            </span>
        </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '12pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Card Details:&nbsp;
            </span>
        </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '12pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Company Name:&nbsp; &nbsp; &nbsp; 
                <input type="text" value={entityName} onChange={(e) => setEntityName(e.target.value)}/>
            </span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '12pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                 Company Address:
            </span>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                 &nbsp; 
                 <input type="text" value={entityAddr} onChange={(e) => setEntityAddr(e.target.value)} />
            </span>
        
        </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Company Postcode:&nbsp; 
                <input type="text"  value={entityPos} onChange={(e) => setEntityPos(e.target.value)}/>
            </span>
            </p><br/>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                ABN:&nbsp; &nbsp; &nbsp; 
                <input type="text" value={abn} onChange={(e) => setAbn(e.target.value)}/>
            </span>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>&nbsp;&nbsp;</span><br/>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Account Contact Name:&nbsp; &nbsp; &nbsp; &nbsp; 
                <input type="text" value={accConName} onChange={(e) => setAccConName(e.target.value)} />
            </span>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>&nbsp;</span><br/>
            </p>
            <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Account Contact Phone:&nbsp; &nbsp; &nbsp; &nbsp; 
                <input type="text" value={accConPh} onChange={(e) => setAccConPh(e.target.value)} />
            </span>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>&nbsp;</span><br/>
            </p>
            <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Account Contact Email:&nbsp; &nbsp; &nbsp; &nbsp; 
                <input type="text" value={accConEmail} onChange={(e) => setAccConEmail(e.target.value)} />
            </span>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>&nbsp;</span><br/>
            </p>
        
            <div dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt', display:'flex',
    flexDirection:'row', alignItems:'center', justifyContent:'flex-strt', width:'60%'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Payment Type:&nbsp;&nbsp;
            </span>

            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                <input type="radio" value="CREDIT CARD" checked={ payType==="CREDIT CARD" ? true : false } onChange={(e) => { setPayType(e.target.value); }}/>
                &nbsp;&nbsp;Credit Card 
            </span>
            {/* <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                <input type="radio" value="ACCOUNT" checked={ payType==="ACCOUNT" ? true : false } onChange={(e) => { setPayType(e.target.value); }}/>
                &nbsp;&nbsp;Account 
            </span>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                <input type="radio" value="PRE-PAID" checked={ payType==="PRE-PAID" ? true : false } onChange={(e) => { setPayType(e.target.value); }}/>
                &nbsp;&nbsp;Pre Paid Cash 
            </span> */}
        </div><br/>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Purchase Order:&nbsp; &nbsp; 
                <input type="text" value={purchOrdReq} onChange={(e) => { setPurchOrdReq(e.target.value); }}  />&nbsp;
            </span>
            </p><br/>
        
        <div dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt', display:'flex',
    flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:'60%'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Please Select:
            </span>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                <input type="radio" value="MasterCard" checked={ cardType==="MasterCard" ? true : false } onChange={(e) => { setCardType(e.target.value); }}/>
                MasterCard 
            </span>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                <input type="radio" value="VisaCard" checked={ cardType==="VisaCard" ? true : false } onChange={(e) => { setCardType(e.target.value); }}/>
                Visa Card 
            </span>
        </div><br/>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Card Number (16 digits): 
            </span>
            <span style={{fontSize: '16pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                <input id="cardNo0" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo1} onChange={(e)=>{ setCardNo1(e.target.value);  }} />
                <input id="cardNo1" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo2} onChange={(e)=>{ setCardNo2(e.target.value);  }} />
                <input id="cardNo2" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo3} onChange={(e)=>{ setCardNo3(e.target.value);  }} />
                <input id="cardNo3" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo4} onChange={(e)=>{ setCardNo4(e.target.value);  }} />
                <input id="cardNo4" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo5} onChange={(e)=>{ setCardNo5(e.target.value);  }} />
                <input id="cardNo5" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo6} onChange={(e)=>{ setCardNo6(e.target.value);  }} />
                <input id="cardNo6" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo7} onChange={(e)=>{ setCardNo7(e.target.value);  }} />
                <input id="cardNo7" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo8} onChange={(e)=>{ setCardNo8(e.target.value);  }} />
                <input id="cardNo8" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo9} onChange={(e)=>{ setCardNo9(e.target.value);  }} />
                <input id="cardNo9" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo10} onChange={(e)=>{ setCardNo10(e.target.value);  }} />
                <input id="cardNo10" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo11} onChange={(e)=>{  setCardNo11(e.target.value);  }} />
                <input id="cardNo11" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo12} onChange={(e)=>{  setCardNo12(e.target.value);  }} />
                <input id="cardNo12" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo13} onChange={(e)=>{  setCardNo13(e.target.value);  }} />
                <input id="cardNo13" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo14} onChange={(e)=>{  setCardNo14(e.target.value);  }} />
                <input id="cardNo14" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo15} onChange={(e)=>{  setCardNo15(e.target.value);  }} />
                <input id="cardNo15" type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardNo16} onChange={(e)=>{  setCardNo16(e.target.value);  }} />
            </span>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>&nbsp;</span><br/>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Expiry Date: (mm/year)&nbsp;
                <DateTimeInput
                    dateValue={expDate}
                    dateSetFn={setExpDate}
                    viewsOnly={"MY"}
                />&nbsp;
            </span>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>&nbsp;</span><br/>
            </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Card Identification Number: 
                <input type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardIdNo1} onChange={(e) => { setCardIdNo1(e.target.value); }} />
                <input type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardIdNo2} onChange={(e) => { setCardIdNo2(e.target.value); }} />
                <input type="text" maxLength="1" placeholder="0" className="smlInputs" value={cardIdNo3} onChange={(e) => { setCardIdNo3(e.target.value); }} /> 
            </span>
            <span style={{fontSize: '9pt',
fontFamily: '&quotCentury Gothic&quot sans-serif',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>
                (last 3 digits located on the back of the credit card)
            </span>
            </p><br/>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Amount to Charge:&nbsp; $&nbsp; 
                <input type="text" value={amt} onChange={(e) => { setAmt(e.target.value); }}  />(AUD, Ex GST)&nbsp;
            </span>
            </p><br/>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Name on the Card:&nbsp; 
                <input type="text" value={cardName} onChange={(e) => setCardName(e.target.value) } />
            </span>
            </p><br/>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Service Provided (Summarized): 
                <input type="text" style={{width:'100%'}} value={serviceProv} onChange={(e) => setServiceProv(e.target.value) } /></span></p><br/><p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            </p><br/>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '0pt', marginBottom: '0pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Authorized Cardholder – Please Sign and Date</span>
            </p><br/>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '6pt', marginBottom: '12pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Print Name: 
            </span>
        <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
            <input type="text" value={printName} onChange={(e) => setPrintName(e.target.value) } />
        </span>
        </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '6pt', marginBottom: '12pt'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Signature:
            </span>
        <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
    {   sign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={sign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> setOpenSign(true)}
                                            >
                                                Add Signature
                                            </div>
    
            {/* <input type="text" value={sign} onChange={(e) => setSign(e.target.value) } /> */}
        </span>
        </p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '7.1pt', marginTop: '6pt', marginBottom: '12pt', display: 'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                Date:
            </span>
            <span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                <DateTimeInput
                    dateSetFn={setSignDate}
                    dateValue={signDate}
                />
            </span>
        </p>
    </b>
    </p>
<p><br/></p>
<p><br/></p> </div>
                </Scrollbars>
                <div className="quote-info-action-btns" onClick={()=>updateTemplateCode()} >
                    <div className="accept-btn">
                    Save Changes
                    </div>
                </div>
            </form>
            <SignaturePadModal
                modalAction={setOpenSign}
                modalStatus={openSign}
                imgFn={signFunc}
            />
        </div>
    );
}

export default FormModal;


// <h1>CREDIT CARD
//         AUTHORISATION FORM</h1>
//     <p></p>
    
//         <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 8pt;"><strong id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><span style="font-size: 9pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">All fields are to be completed.&nbsp;You&nbsp;may&nbsp;cancel&nbsp;this&nbsp;authorization&nbsp;at&nbsp;any&nbsp;time by contacting us in writing. This authorization will remain in effect until cancelled. I understand that my information will be saved for future transaction.</span></strong></p>
//         <p><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><br>
//             </b></p>
//         <p dir="ltr" style="line-height: 1.2; margin-left: 7.1pt; margin-top: 12pt; margin-bottom: 12pt;"><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I, ${custName}, hereby give </span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Enviro Waste services Group Pty Ltd</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> the&nbsp;</span></b></p>
//         <p><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;">
//             </b></p>
//         <p dir="ltr" style="line-height: 1.2; margin-left: 7.1pt; margin-top: 12pt; margin-bottom: 12pt;"><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">authorisation to charge the amount listed below to the credit card provided herein, prior to services being provided.&nbsp;&nbsp;</span></b></p>
//         <p><b id="docs-internal-guid-ed956599-7fff-0d5c-fa48-d7362f0ffec3" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;">
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Card Details:&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company Name:&nbsp; &nbsp; &nbsp; ${entityName}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company address:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> &nbsp; ${entityAddr}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company Postcode:&nbsp; ${entityPos}</span></p><br>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">ABN:&nbsp; &nbsp; &nbsp; ${abn}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Name:&nbsp; &nbsp; &nbsp; &nbsp; ${accConName}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Phone:&nbsp; &nbsp; &nbsp; &nbsp; ${accConPh}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Email:&nbsp; &nbsp; &nbsp; &nbsp; ${accConEmail}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Payment Type:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${ payType==='CREDIT CARD' ? 'Credit Card' : payType==='ACCOUNT' ? 'Account' : 'Pre Paid Cash' }</span></p><br>
                
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Purchase Order Required:&nbsp; &nbsp; &nbsp; &nbsp; ${purchOrdReq}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Please Select:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${!cardType ? `&#x2610;`:`&#x2611;`}MasterCard &nbsp;&nbsp;&nbsp;&nbsp; ${cardType ? `&#x2610;`:`&#x2611;`}Visa Card </span></p><br>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Card Number (16 digits): </span><span style="font-size: 16pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${cardNo1}, ${cardNo2}, ${cardNo3}, ${cardNo4}, ${cardNo5}, ${cardNo6}, ${cardNo7}, ${cardNo8}, ${cardNo9}, ${cardNo10}, ${cardNo11}, ${cardNo12}, ${cardNo13}, ${cardNo14}, ${cardNo15}, ${cardNo16},</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Expiry Date: (mm/year)&nbsp; ${dayjs(expDate).format('MM/YYYY')}&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Card Identification Number: ${cardIdNo1}, ${cardIdNo2}, ${cardIdNo3} </span><span style="font-size: 9pt; font-family: &quot;Century Gothic&quot;, sans-serif; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(last 3 digits located on the back of the credit card)</span></p><br>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Amount to Charge:&nbsp; $&nbsp; ${amt} (AUD, Ex GST)&nbsp;</span></p><br>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name on the Card:&nbsp; ${cardName}</span></p><br>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Service Provided (Summarized): ${serviceProv}</span></p><br>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">_________________________________________________________________________</span></p><br>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Authorized Cardholder – Please Sign and Date</span></p><br>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 6pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Print Name: </span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${printName}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 6pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Signature:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${sign}</span></p>
//                 <p dir="ltr" style="line-height: 1.295; margin-left: 7.1pt; margin-top: 6pt; margin-bottom: 12pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Date:</span><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${dayjs(signDate).format('DD/MM/YYYY')}</span></p>
//             </b><br class="Apple-interchange-newline" style="font-style: normal; font-variant-caps: normal; font-weight: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0);"></p>
//         <p></p>
//         <p></p>
//         <p><br></p>