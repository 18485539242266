import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Scrollbar from 'react-custom-scrollbars'
import JoditEditor from "jodit-react"
import { EnviroLogo } from '../../assets/images'
import Swal from 'sweetalert2'
import { accept_quote, quote_response, refreshTemplateList, reject_quote } from '../common/action'
import QuoteEditor from '../waste/quoteEditor'
import FormModal2 from '../waste/formModalTemp2'
import FormModal4 from '../waste/formModalTemp4'
import { CheckCircleRounded } from '@material-ui/icons'
import { CircularProgress } from '@material-ui/core'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


function WasteIndex({ 
    uuid, 
    selected_quote, 
    select_quote, 
    quote_info, 
    templates, 
    selected_quote_id, client, 
    update_template, 
    reject_quote_fx, 
    accept_quote_fx, 
    attach_quote_response_fx,
    attachUpdateTemp,
    templateData,
    updateClientResponse
}) {
    const history = useHistory();
    const editor_ref = useRef()    

    useEffect(() => {
        const checkUuidIsPresent = () => {
            if(uuid === '') {
                history.push("/");
            } 
        }
        checkUuidIsPresent()
    }, [uuid, history])

    const [modalOpen, setModalOpen] = useState(false)
    const [attOpen, setAtt]   = useState(0)
    const [formModal1, setFormModal1] = useState(false)
    const [formModal2, setFormModal2] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)

    const [btnText, setBtnText] = useState('Customer Account Details')

    const config = {
        readonly: true,
        toolbar: false,
        minHeight: '1000px'
    }

    const update_current_notes_for_doc = () => {
        console.log("CURRNOTES",templates,selected_quote_id)

        let findItemExist = templates && templates.find(item => item.id === selected_quote_id)
        let findItemIndex = templates.indexOf(findItemExist)
        let temp_arr = templates;
        temp_arr[findItemIndex] = {
            id: selected_quote_id,
            template: selected_quote,
            template_name: templates && templates.find(item=> item.id===selected_quote_id).template_name
        }
        console.log('TEMPARR',temp_arr)
        update_template(temp_arr)
        editor_ref.current.value=""
    }

    const updateAttachTemplate = (str, abnVal) => {
        console.log("TEMPLATESTR",str,templates,selected_quote_id)

        let findItemExist = templates.find(item => item.id === selected_quote_id)
        let findItemIndex = templates.indexOf(findItemExist)
        let temp_arr = templates;
        temp_arr[findItemIndex] = {
            id: selected_quote_id,
            template: str,
            template_name: templates.find(item=> item.id===selected_quote_id).template_name
        }
        attachUpdateTemp(temp_arr)
        let dataObj = templateData
        console.log(templateData,abnVal)
        if(templateData!==null){
            var aKeys = Object.keys(dataObj).sort();
            var bKeys = Object.keys(abnVal).sort();
            console.log('aK',aKeys,'bK'.bKeys)
            if(JSON.stringify(aKeys) === JSON.stringify(bKeys)){
                console.log('FN1',JSON.stringify(aKeys),JSON.stringify(bKeys))
                Object.entries(dataObj).forEach((k, _)=>{
                    console.log(k,dataObj,abnVal)
                    if(k in abnVal){
                        dataObj.k = abnVal.k 
                    }
                })
            }else{
                console.log('FN2',dataObj,abnVal)
                let temp = dataObj
                dataObj = {...temp, ...abnVal}
            }
        }
        else{
            console.log('FN3',abnVal)
            dataObj = {...abnVal}
        }
        console.log('DataObj=>',dataObj,abnVal)
        select_quote(str, selected_quote_id)
        updateClientResponse(dataObj)
    }

    const rejectBtnClick = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to reject the quote",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF0000',
            cancelButtonColor: '#13CD6A',
            confirmButtonText: 'Yes, Reject it!'
        }).then((result) => {
            if (result.value) {

                const success = (msg) => {
                    setBtnLoader(false)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Quote Rejected!',
                        showConfirmButton: false,
                        timer: 1500
                      })
                    history.push('/')
                }

                const failed = (msg) => {
                    setBtnLoader(false)
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: msg
                      })
                    history.push('/')
                }

                reject_quote_fx(uuid, success, failed)

            }
        })
    }


    const acceptBtnClick = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to accept the quote",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#13CD6A',
            cancelButtonColor: '#cdcdcd',
            confirmButtonText: 'Yes, Accept!'
        }).then((result) => {
            if (result.value) {

                const success = (message) => {
                    setBtnLoader(false)
                    Swal.fire({
                        icon: 'success',
                        title: 'Quote Accepted',
                        text: message
                    })
                    history.push(`/${uuid}`)
                }

                const failed = (msg) => {
                    setBtnLoader(false)
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: msg
                      })
                    history.push('/')
                }
                console.log(templates)

                templates.map(async (item, key) => {
                    if(key !== 0) {
                        let data = new FormData()
                        data.append('template_content', item.template)
                        data.append('template_id', item.id)
                        
                        await attach_quote_response_fx(uuid, data)
                    }
                    if(key === templates.length - 1) {
                        let formdata = new FormData()
                        formdata.append('template', templates[0].template)
                        console.log(templateData)
                        if(templateData){
                            for (const [key, value] of Object.entries(templateData)){
                                console.log(key,value,templateData)
                                formdata.append(key,value)
                            }
                        }
                        // Object.keys(templateData).forEach((key, val) => {

                        //     formdata.append(key,val)
                        // })
                        accept_quote_fx(uuid, formdata, success, failed)
                    }
                    return null
                })
            }
        })
    }

    const getSunEditorInstance = (sunEditor) =>{
        editor_ref.current = sunEditor
    }

    return (
        <div className="app-layout">
        <div className="navbar-layout">
          <img src={EnviroLogo} alt="" />

        <div style={{ display: 'flex', flexDirection:'row', flexWrap:'wrap' }}>
            {
                templates.map((item, key) => {
                    return (
                        <div 
                            key={key}
                            onClick={async () => {
                                update_current_notes_for_doc(key===0 ? "main_quote" : item.template_name)
                                console.log(item,key,item.template_name)
                                setBtnText(item.template_name.length > 25 ? 
                                    `${item.template_name.substring(0,15)}...${item.template_name.substring(item.template_name.length-4,item.template_name.length)}` 
                                        : item.template_name)
                                
                                await select_quote(item.template, item.id, templateData)
                                if(item.template_name && (item.template_name.includes("Credit_Card_Auth") || item.template_name.includes("credit_card_auth"))){
                                    setAtt(2)
                                }
                                else if(item.template_name && (item.template_name.includes("Credit_App") || item.template_name.includes("credit_app"))){
                                    setAtt(3)
                                }
                                else if(key===0){
                                    console.log('MAINQ')
                                    setAtt(0)
                                }
                                else{
                                    setAtt(1)
                                }
                            }}
                            className={item.id === selected_quote_id ? 'quote-selector-btn quote-selector-btn-active' : 'quote-selector-btn'}>
                            {
                                key === 0 ? (<p>Quote</p>) : 
                                (<p>
                                    {item.template_name.length > 25 ? 
                                    `${item.template_name.substring(0,15)}...${item.template_name.substring(item.template_name.length-4,item.template_name.length)}` 
                                        : item.template_name}
                                </p>)
                            }
                            {   item.template && item.template.includes('<!--DATAENTERED-->') ?
                                <span className="checkMarkCircle">
                                    <CheckCircleRounded style={{ color:'green' }} />
                                </span>
                                : null
                            }
                        </div>
                    )
                })
            }
            
        </div>
        
        {
            btnLoader ?
            <CircularProgress />
            :
          <div className="navbar-action-layout">
            <button onClick={() => {
                setBtnLoader(true)
              rejectBtnClick()
            }} 
                style={{ height: 40, width: 130 }}
                className="reject-btn">Reject</button>
            <button onClick={() => {
                setBtnLoader(true)
              acceptBtnClick()
              }}
              style={{ height: 40, width: 130 }}
              className="accept-btn">Accept</button>
          </div>
        }
        
        </div>
        <Scrollbar style={{ height: '85vh' }}>
          <div className="quote-editor-layout">
              { (attOpen===2 || attOpen===3 || attOpen===0) ?
              (modalOpen || formModal1 || formModal2) ? null :
            <div className={    attOpen===2 || attOpen===3 || attOpen===0 ?
                "quote-selector-btn quote-fill-dataBtn quote-update-active" 
            :   "quote-selector-btn quote-fill-dataBtn quote-update-inactive" }
            style={{ marginBottom: '20px', width:'250px' }} 
                onClick={()=>{
                    if(attOpen===2)
                        setFormModal2(true)
                    else if(attOpen===3)
                        setFormModal1(true)
                    else if(attOpen===0)
                        setModalOpen(true)
                    
                    console.log(attOpen,formModal1,formModal2,modalOpen)
                }} >
                <p>Fill {btnText==='main_quote' ? 'Customer Account Details' : btnText}</p>
                
            </div>
            :   null
            }
            {
            attOpen===2 || attOpen===3 || attOpen===0 ?
                <div style={{ height:'10vh', width:'10vw', display:'flex' }}></div>
                : null
            }
            <div
            onClick={()=>{
                if(attOpen===2)
                setFormModal2(true)
            else if(attOpen===3)
                setFormModal1(true)
            else if(attOpen===0)
                setModalOpen(true)
            }}>
            {
                selected_quote ?
                attOpen===0 ? 
                <SunEditor
                    defaultValue={'<p>Loading content...</p>'}
                    setContents={selected_quote}
                    getSunEditorInstance={getSunEditorInstance}
                    placeholder='Content loading...'
                    hideToolbar={true}
                    disable={true}
                    hide={attOpen!==0}
                    height='50%'
                    width='100%'
                    setOptions={{
                        resizingBar : false,
                        resizeEnable : false,
                        addTagsWhitelist : '*',
                        attributesWhitelist: {
                            'all' : 'style|data-.+',
                            'input' : 'checked|name',
                            '???' : '*'
                        }
                    }}
                    
                />
                :
                <div key="editor1">
                <JoditEditor
                    ref={editor_ref}
                    value={selected_quote}
                    config={config}
                    tabIndex={1}
                />
                </div>
                :
                null
            }
            
            </div>


            
          </div>
        </Scrollbar>
        <QuoteEditor templateStr={selected_quote} setTemplateStr={select_quote} qId={selected_quote_id} modalOpen={modalOpen} setModalOpen={setModalOpen} updateClientResponse={updateClientResponse} templateData={templateData}
        client={client}
         />
        {   formModal1  ?
        <FormModal2 modalOpen={formModal1} setModalOpen={setFormModal1} editAction={updateAttachTemplate} templateName={templates && templates.find(item=> item.id===selected_quote_id).template_name} client={client}
        />  :   null
        }
        {   formModal2  ?
        <FormModal4 modalOpen={formModal2} setModalOpen={setFormModal2} editAction={updateAttachTemplate} templateName={templates && templates.find(item=> item.id===selected_quote_id).template_name} client={client}
        />  :   null
        }
      </div>
    )
}

const mapStateProps = (state) => {
    const { uuid, selected_quote, quote_info, templates, templateData, selected_quote_id, client } = state.storeMain
    return { uuid, selected_quote, quote_info, templates, templateData, selected_quote_id, client }
}

const mapDispatchToProps = (dispatch) => {
    return {
        select_quote: (quote, id) => dispatch({ type: 'UPDATE_SELECTED_QUOTE', quote: quote, id: id }),
        update_template: (templates) => dispatch({ type: 'UPDATE_TEMPLATE', templates: templates }),
        reject_quote_fx: (uuid, success, failed) => dispatch(reject_quote(uuid, success, failed)),
        accept_quote_fx: (uuid, data, success, failed) => dispatch(accept_quote(uuid, data, success, failed)),
        attach_quote_response_fx: (uuid, data) => dispatch(quote_response(uuid, data)),
        attachUpdateTemp : (tempArr) => dispatch(refreshTemplateList(tempArr)),
        updateClientResponse : (abnVal) => dispatch({ type: 'UPDATE_CLIENT_RESPONSE', templateDataObj : abnVal })
      }
}

export default connect(mapStateProps, mapDispatchToProps) (WasteIndex)


