import React, {useState} from 'react';
import dayjs from 'dayjs'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import DateTimeInput from '../common/DateTimeInput';
import Scrollbars from 'react-custom-scrollbars';
import { GoogleMapInit, SearchGoogleAddress } from '../common/FindLatLng';
import { HOME_LATITUDE, HOME_LONGITUDE } from '../../store/config';
import SignaturePadModal from '../common/SignaturePadModal'

function QuoteEditor ({templateStr, setTemplateStr, qId, modalOpen, setModalOpen,updateClientResponse, templateData, client}) {

    const [templateObj, setTemplateObj] = useState({
        
            bName   :   '',
            bPhone  :   '',
            email   :   '',
            accPer  :   '',
            accNo   :   '',
            bilAddr :   '',
            abn     :   client && client.abn ? client.abn : '',
        
            sName   :   client ? client.name : '',
            sAddr   :   client && client.site_address ? client.site_address : '',
            sCont   :   client && client.site_contact_name ? client.site_contact_name : '',
            phone   :   '',
            sConNo  :   '',
            sConEmail:  '',

            custName :  '',
            custSign :  '',
            custPos  :  '',
    })
    const [signDate, setSignDate] = useState("")
    const [openSign, setOpenSign] = useState(false)
    const [markers, setMarkers] = useState([{
        lat: HOME_LATITUDE,
        lng: HOME_LONGITUDE
    }]);
    let mapInit = GoogleMapInit()

    // const [errTxt, setErrTxt] = useState('')
    // const [errTxt2, setErrTxt2] = useState('')

    // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const templateUpdate = () =>{

        let tempObj = {
            'main_quote_business_name' : templateObj.bName,
            'main_quote_business_phone': templateObj.bPhone,
            'main_quote_email'         : templateObj.email,
            'main_quote_accounts_person' : templateObj.accPer,
            'main_quote_accounts_number' : templateObj.accNo,
            'main_quote_billing_address' : templateObj.bilAddr,
            'main_quote_abn'             : templateObj.abn,
            'main_quote_site_name'       : templateObj.sName,
            'main_quote_site_address'    : templateObj.sAddr,
            'main_quote_site_contact_name'    : templateObj.sCont,
            'main_quote_phone_number'    : templateObj.phone,
            'main_quote_site_contact_number' : templateObj.sConNo,
            'main_quote_site_contact_email'  : templateObj.sConEmail,
            'main_quote_customer_name'   : templateObj.custName,
            'main_quote_customer_signature' : templateObj.custSign,
            'main_quote_position'        : templateObj.custPos,
            'main_quote_date'            : dayjs(signDate).format('DD-MM-YYYY'),
            'site_latitude'              : templateObj.sAddr!=='' ? markers[0].lat : null,
            'site_longitude'             : templateObj.sAddr!=='' ? markers[0].lng : null
        }

        let strStart1 = `<!--sitedetailsstart-->
        <table class="c8" style="border-spacing: 0;border-collapse: collapse;margin-right: auto;width: 100%;">
            <tbody>
            <tr class="c29" style="min-height: 27px;">
                <td class="c4" colspan="4" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;vertical-align: top;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 746px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-left-color: #00b0f0 !important;border-right-color: #00b0f0 !important;background-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c16" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 19px;font-family: Arial, sans-serif;font-style: normal;">Site Details</span></p>
                </td>
            </tr>
            <tr class="c29" style="min-height: 27px;">
                <td class="c22 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">Site Address</span></p>
                </td>
                <td class="c27" colspan="3" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 559px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                     <!--siteaddressstart-->
                    <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">${templateObj.sAddr}</span></p>
                     <!--siteaddressend-->
                </td>
            </tr>
            <tr class="c28" style="min-height: 0px;">
                <td class="c22 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">Site Contact Name</span></p>
                </td>
                <td class="c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                     <!--sitecontactstart-->
                    <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">${templateObj.sCont}</span></p>
                     <!--sitecontactend-->
                </td>
                <td class="c15 c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">Site Contact Email</span></p>
                </td>
                <td class="c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                     <!--sitecontactemailstart-->
                    <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">${templateObj.sConEmail}</span></p>
                     <!--sitecontactemailend-->
                </td>
            </tr>
            <tr class="c28" style="min-height: 0px;">
                <td class="c22 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">Site Contact Phone</span></p>
                </td>
                <td class="c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                     <!--sitecontactphonestart-->
                    <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">${templateObj.sConNo}</span></p>
                     <!--sitecontactphoneend-->`
        let strStart2 = `<!--customeracceptancestart-->
        <table class="c8" style="border-spacing: 0;border-collapse: collapse;margin-right: auto;width: 100%;">
            <tbody>
                <tr class="c30" style="min-height: 32px;">
                    <td class="c4" colspan="4" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;vertical-align: top;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 746px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-left-color: #00b0f0 !important;border-right-color: #00b0f0 !important;background-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                        <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c23" style="font-size: 19px;color: #ffffff;font-weight: 700;">Customer Acceptance</span></p>
                    </td>
            
            </tr>
            <tr class="c24" style="min-height: 65px;">
                <td class="c14 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">Customer Name</span></p>
                </td>
                <td class="c14" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <!--cusomernamestart-->
                    <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c17" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">${templateObj.sName}</span></p>
                    <!--cusomernameend-->
                </td>
                <td class="c14 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">Customer Signature</span></p>
                </td>
                <td class="c14" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <!--cusomersignaturestart-->
                    <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 30px;"><span class="c17" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">
                    <img src=${templateObj.custSign} style="margin-left:100px;margin-top:0px;" width="200" height="100">
                    </span></p>
                    <!--cusomersignatureeend-->
                </td>
            </tr>
            <tr class="c28" style="min-height: 0px;">
                <td class="c14 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">ABN Number</span></p>
                </td>
                <td class="c14" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <!--abnnumberstart-->
                    <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c17" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">${templateObj.abn}</span></p>
                    <!--abnnumberend-->
                </td>
                <td class="c14 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">Date</span></p>
                </td>
                <td class="c14" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                    <!--datestart-->
                    <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family: Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c17" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family: Arial, sans-serif;font-style: normal;">${signDate ? dayjs(signDate).format('DD-MM-YYYY') : ''}</span></p>
                    <!--dateend-->
                </td>
            </tr>
            </tbody>
        </table>
        ${ Object.entries(templateObj).some((_,v)=> v!=='') ? `<!--DATAENTERED-->` : ``}
        <!--customeracceptanceend-->`
        
        let op1 = templateStr.substring(0, templateStr.indexOf('<!--sitedetailsstart-->')).replace('<!--sitedetailsstart-->','')
        let op2 = templateStr.substring(templateStr.indexOf('<!--sitecontactphoneend-->'), templateStr.indexOf('<!--customeracceptancestart-->')).replace('<!--sitecontactphoneend-->','')
        let op3 = templateStr.substring(templateStr.indexOf('<!--customeracceptanceend-->'), templateStr.length).replace('<!--customeracceptanceend-->','')
        

        let dataObj = templateData
        if(templateData!==null){
            var aKeys = Object.keys(dataObj).sort();
            var bKeys = Object.keys(tempObj).sort();
            console.log('aK',aKeys,'bK'.bKeys)
            if(JSON.stringify(aKeys) === JSON.stringify(bKeys)){
                console.log('FN1',JSON.stringify(aKeys),JSON.stringify(bKeys))
                Object.entries(dataObj).forEach((k, _)=>{
                    console.log(k,dataObj,tempObj)
                    if(k in tempObj){
                        dataObj.k = tempObj.k 
                    }
                })
            }else{
                console.log('FN2',dataObj,tempObj)
                let temp = dataObj
                dataObj = {...temp, ...tempObj}
            }
        }
        else{
            console.log('FN3',tempObj)
            dataObj = {...tempObj}
        }
        console.log('UPDATECLIENTRESP=>',dataObj)
        console.log('TEMPLATESTR',op1+strStart1+op2+strStart2+op3)
        updateClientResponse(dataObj)
        setTemplateStr(op1+strStart1+op2+strStart2+op3, qId)

        setModalOpen(false)
    }

    const signFunc = (val) => {
        setTemplateObj({ ...templateObj, custSign : val})
    }

    return (
        <div className={ modalOpen ? "quoteModalCloseBx quoteModalBg" : "quoteModalBg"}>
            <div className="quoteModalContent">
                <div style={{ display:'flex', flexDirection: 'row', width:'100%', justifyContent:'space-between' }}>
                <div className="quoteModalTitle" style={{ textAlign: 'center', width: '100%' }}>Edit Quote</div>
                <HighlightOffRoundedIcon style={{ fontSize:'25px', color:'blue' }} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); setModalOpen(false);}}/>
                </div>
                <Scrollbars style={{ height:'75vh' }}>
                <form className="quoteModalContentBody">
                <div className="quoteModalTitle" style={{ marginBottom : '1.5rem' }}>Site Details</div>
                    {/* <div className="quoteModalTitle">Customer Account Details</div> */}

                    
                    
                    <div className="quoteModalBody">
                        <div className="quoteModalSubBody">

                        <div className="quoteModalSubBodyContent">
                                <div>
                                    <div className="quoteModalLn1">Site Name</div>
                                    <div className="quoteModalLn2">:</div>
                                    <div className="quoteModalLn3">
                                        <input type="text" value={templateObj.sName} onChange={(e)=> setTemplateObj({ ...templateObj, sName : e.target.value})}/>
                                    </div>
                                </div>
                                <div>
                                    <div className="quoteModalLn1">Site Address</div>
                                    <div className="quoteModalLn2">:</div>
                                    <div className="quoteModalLn3">
                                        {/* <input type="text" value={templateObj.sAddr} onChange={(e)=> setTemplateObj({ ...templateObj, sAddr : e.target.value})}/> */}
                                        {
                                            !mapInit.status ? null :
                                        <SearchGoogleAddress 
                                            cls={{ outerBox : 'searchAddressSite', innerBox : 'client-input'}} 
                                            inputVal={templateObj.sAddr}
                                            inputPlaceHolder={"Site Address"}
                                            inputFn={(e) => { setTemplateObj({ ...templateObj, sAddr : e})}}
                                            setMarkers={setMarkers} />
                                        }            
                                    </div>
                                </div>
                                <div>
                                    <div className="quoteModalLn1">Site Contact Name</div>
                                    <div className="quoteModalLn2">:</div>
                                    <div className="quoteModalLn3">
                                        <input type="text" value={templateObj.sCont} onChange={(e)=> setTemplateObj({ ...templateObj, sCont : e.target.value})}/>
                                    </div>
                                </div>
                                <div>
                                    <div className="quoteModalLn1">Site Contact Email</div>
                                    <div className="quoteModalLn2">:</div>
                                    <div className="quoteModalLn3">
                                        <input type="text" value={templateObj.sConEmail} onChange={(e)=> setTemplateObj({ ...templateObj, sConEmail : e.target.value})}/>
                                    </div>
                                </div>
                                <div>
                                    <div className="quoteModalLn1">Site Contact Number</div>
                                    <div className="quoteModalLn2">:</div>
                                    <div className="quoteModalLn3">
                                        <input type="text" value={templateObj.sConNo} onChange={(e)=> setTemplateObj({ ...templateObj, sConNo : e.target.value})}/>
                                    </div>
                                </div>
                                <div>
                                    <div className="quoteModalLn1">ABN</div>
                                    <div className="quoteModalLn2">:</div>
                                    <div className="quoteModalLn3">
                                        <input type="text" value={templateObj.abn} onChange={(e)=>{ setTemplateObj({ ...templateObj, abn : e.target.value}); }}/>
                                    </div>
                                </div> 
                                <div>
                                    <div className="quoteModalLn1">Signature of Approval</div>
                                    <div className="quoteModalLn2">:</div>
                                    <div className="quoteModalLn3">
                                            {   templateObj.custSign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={templateObj.custSign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> setOpenSign(true)}
                                            >
                                                Add Signature
                                            </div>
                                        {/* <input type="text" value={templateObj.custSign} onChange={(e)=> setTemplateObj({ ...templateObj, custSign : e.target.value})}/> */}
                                    </div>
                                </div>
                                

                                <div>
                                    <div className="quoteModalLn1">Date </div>
                                    <div className="quoteModalLn2">:</div>
                                    <div className="quoteModalLn3">
                                        <DateTimeInput
                                            dateSetFn={setSignDate}
                                            dateValue={signDate}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                        {/* </div> */}
                        <div className="quote-info-action-btns" >
                            <div className="accept-btn" onClick={() => templateUpdate()}>
                            Save Changes
                            </div>
                        </div>
                    </div>
                </form>
                </Scrollbars>
            </div>
            <SignaturePadModal
                modalAction={setOpenSign}
                modalStatus={openSign}
                imgFn={signFunc}
            />
        </div>
    );
}

export default QuoteEditor;

//<!-- ##Account00dstart --> 
//  <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//      1. Accounts/Business Information&nbsp;</span></p><p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Business Name:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Business Phone :</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Email:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Accounts Person:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p><p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Accounts Number:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Billing Address:&nbsp;</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         ABN:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         
//      2. Contact Information&nbsp;</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Site Name:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Site Address:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Site Contact Name:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Phone Number:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Site Contact number:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Site Contact Email:</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                                                             
//      3. Customer Acceptance Signature</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Signature:</span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span></p><p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//         Position:</span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span>
//         
//         <span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span>
//         <span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
//         <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Date:</span></p><br> 
// <!-- ##Account00dend --> 

// <!-- ##Account00dstart --> 
//         <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//             1. Accounts/Business Information&nbsp;</span></p><p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Business Name: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.bName +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Business Phone : </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.bPhone +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Email: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.email +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Accounts Person: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.accPer +`</span></p><p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Accounts Number: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.accNo +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Billing Address: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.bilAddr +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                ABN: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.abn +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//             2. Contact Information&nbsp;</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Site Name: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.sName +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Site Address: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.sAddr +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Site Contact Name: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.sCont +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Phone Number: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.phone +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Site Contact number: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.sConNo +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//                Site Contact Email: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.sConEmail +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
                                                             
//       3. Customer Acceptance Signature</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//          Name: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.custName +`</span></p> <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//          Signature: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.custSign +`</span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span></p><p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//          Position: </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">`+ templateObj.custPos +`</span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span>
         
//          <span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span>
//          <span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp; </span><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
//          <p dir="ltr" style="line-height:1.7999999999999998;margin-left: 72pt;margin-top:0pt;margin-bottom:5pt;"><span style="font-size:12pt;font-family:Nunito,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
//          Date: `+ dayjs(signDate).format('DD-MM-YYYY') +`</span></p><br> 
//          ${ Object.entries(templateObj).some((_,v)=> v!=='') ? `<!--DATAENTERED-->` : ``}

//  <!-- ##Account00dend --></br>
