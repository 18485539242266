import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { EnviroLogo } from './assets/images' 
import HomeApp from './screens/home'
import EditorApp from './screens/editor/WasteIndex'
// import PumpEditorApp from './screens/editor/PumpIndex'

export default function AppRouter() {

    const AppNotFOund = () => {
        return (
            <div className="app-layout">
                <div className="navbar-layout">
                <img src={EnviroLogo} alt="" />
                </div>
                <div className="alert-layout">
                    <div className="alert-main">
                    <p>404! Not Found</p>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <BrowserRouter>
            <Switch>
                <Route path="/waste-editor" name="waste-editor" component={EditorApp} />
                {/* <Route path="/pumps-editor" name="waste-editor" component={PumpEditorApp} /> */}
                <Route path="/:uuid" name="home" component={HomeApp} />
                <Route path="" name="error-not-found" component={AppNotFOund} />
            </Switch>
        </BrowserRouter>
    )
}
