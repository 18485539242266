let initialState = {
    templates: [],
    oldTemplates: [],
    uuid: '',
    quote_info: {},
    message: '',
    selected_quote: '',
    selected_quote_id: '',
    selected_quote_name: 'main_quote',
    templateData: null,
    client: null
}

const storeMain = (state = initialState, actions) =>{

    switch (actions.type) {

        case "UPDATE_QUOTE_INFO":
            return {
                ...state,
                quote_info: actions.quote
            }

        case "SET_OLD_TEMPLATES":
            return {
                ...state,
                oldTemplates: actions.templates
            }

        case "UPDATE_TEMPLATE":
            return {
                ...state,
                templates: actions.templates
            }

        case "UPDATE_CLIENT_RESPONSE":
            return {
                ...state,
                templateData: actions.templateDataObj
            }

        case "UPDATE_SELECTED_QUOTE":
            let newArr = []
            if(state.templates && actions.id && state.templates.find(el => el.id===actions.id)){
                newArr = state.templates
                let newObj = newArr.find(el => el.id===actions.id)
                let newObjIdx = newArr.indexOf(el => el.id===actions.id)
                newObj.template = actions.quote
                newArr[newObjIdx] = newObj
            }

            return {
                ...state,
                selected_quote: actions.quote,
                selected_quote_id: actions.id,
                templates : newArr
            }

        case "UPDATE_UUID":
            return {
                ...state,
                uuid: actions.uuid
            }

        case "UPDATE_MESSAGE":
            return {
                ...state,
                message: actions.message
            }
        
        case "UPDATE_CLIENT":
            return {
                ...state,
                client : actions.client
            }
    
        default:
            return state;
    }
}

export default storeMain