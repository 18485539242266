import React, {useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import DateTimeInput from '../common/DateTimeInput';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import dayjs from 'dayjs';
import SignaturePadModal from '../common/SignaturePadModal';

function FormModal ({modalOpen, setModalOpen, editAction, templateName, client}) {

    const [formObj, setFormObj] = useState({entityName       :   client && client.company_name ? client.company_name : "",
        entityAddr       :   client && client.company_address ? client.company_address : "",
        entityPostal     :   client && client.company_postcode ? client.company_postcode : "",
        abn              :   client && client.abn ? client.abn : "",
        accConName       :   client && client.company_contact_name ? client.company_contact_name : 
                                client && client.site_contact_person ? client.site_contact_person : "",
        accConPhone      :   client && client.company_contact_number ? client.company_contact_number :
                                client && client.site_contact_phone ? client.site_contact_phone :
                                client && client.site_contact_mob ? client.site_contact_mob : "",
        errEmail        :   false,

        accConEmail     :   client && client.company_email ? client.company_email : "",
        soleTrader      :   false,
        partnership     :   false,
        privateComp     :   false,
        indiv           :   false,
        oth             :   false,
        othExpl         :   "",
        
        dirInfoSign     :   "",
        dirInfoName     :   "",
        
        dirInfoPos      :   "",
        envRejectRes    :   "",
        checkedBy       :   "",
        finApprName     :   "",
        
        finApprSign     :   "",
        finApprPos      :   "",
        genCredName     :   "",
        genCredSign     :   "",
        genCredPos      :   "",
        
        credPirName     :   "",
        credPirSign     :   "",
        
        credPirPos      :   "",
        gur1Name        :   "",
        gut1Sign        :   "",
        wit1Name        :   "",
        wit1Sign        :   "",
        wit1Addr        :   "",
        gur2Name        :   "",
        gut2Sign        :   "",
        wit2Name        :   "",
        wit2Sign        :   "",
        wit2Addr        :   "",
        dayOf           :   new Date().getDate() === 1 ? '1st' : 
                            new Date().getDate() === 2 || new Date().getDate()===22 ? `${new Date().getDate()}nd` :
                            new Date().getDate() === 3 || new Date().getDate()===23 ? `${new Date().getDate()}rd` :
                            `${new Date()}th`,
        monthOf         :   dayjs(new Date()).format('MMMM'),
        yearOf          :   dayjs(new Date()).format('YY')      
    })

    const [bCreditRef, setBcreditRef] = useState([
        {bName : '', bPhone : '', bEmail : '', bErrEmail : false},
        {bName : '', bPhone : '', bEmail : '', bErrEmail : false},
        {bName : '', bPhone : '', bEmail : '', bErrEmail : false}
    ])

    const [dirInfo, setDirInfo] = useState([
        {dName : '', dAddr : '', dPhone : '', dEmail : '', dErrEmail : false},
        {dName : '', dAddr : '', dPhone : '', dEmail : '', dErrEmail : false}
    ])
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


    const [dirInfoDate, setDirInfoDate] = useState(new Date())
    const [finApprDate, setFinApprDate] = useState(new Date())
    const [genCredDate, setGenCredDate] = useState(new Date())
    const [credPirDate, setCredPirDate] = useState(new Date())
    const [credRef, setCredRef] = useState(false)
    const [codAcc, setCodAcc] = useState(false)
    const [applAppr, setApplAppr] = useState(false)
    const [checkedByDate, setCheckedByDate] = useState(new Date())
    const [keyOpt, setKeyOpt] = useState(null)
    const [signModal, setSignModal] = useState(false)


    const OpenSign = (val) =>{
        setKeyOpt(val)
        setSignModal(true)
    }
    const signFunc = (val) => {
        let temp = formObj
        temp[keyOpt] = val
        setFormObj(temp)
        setKeyOpt(null)
    }

    const updateTemplateCode = () =>{

        let tempObj = {
            'credit_application_business_contact_information' : {
                'entity_name'           : formObj.entityName, 
                'entity_address'        : formObj.entityAddr, 
                'entity_postalcode'     : formObj.entityPostal, 
                'abn'                   : formObj.abn,
                'account_contact_name'  : formObj.accConName, 
                'account_contact_phone' : formObj.accConPhone,
                'account_contact_email' : formObj.accConEmail, 
                'type_of_entity'        : [formObj.soleTrader ? 'Sole Trader' : null, formObj.partnership ? 'Partnership' : null, formObj.privateComp ? 'Private Company' : null, formObj.indiv ? 'Individual' : null, formObj.oth ? formObj.othExpl : null ], 
            },
            'credit_application_business_credit_ref' : [
                { 'name' :  bCreditRef[0].bName, 'phone' : bCreditRef[0].bPhone, 'email' : bCreditRef[0].bEmail},
                { 'name' :  bCreditRef[1].bName, 'phone' : bCreditRef[1].bPhone, 'email' : bCreditRef[1].bEmail},
                { 'name' :  bCreditRef[2].bName, 'phone' : bCreditRef[2].bPhone, 'email' : bCreditRef[2].bEmail}
            ],
            'credit_application_directors_info' : [
                { 'name' : dirInfo[0].dName, 'address' : dirInfo[0].dAddr, 'phone' : dirInfo[0].dPhone, 'email' : dirInfo[0].dEmail },
                { 'name' : dirInfo[1].dName, 'address' : dirInfo[1].dAddr, 'phone' : dirInfo[1].dPhone, 'email' : dirInfo[1].dEmail }
            ],
            'credit_application_director_info_signed' : formObj.dirInfoSign,
            'credit_application_director_info_name'   : formObj.dirInfoName,
            'credit_application_director_info_date'   : dirInfoDate,
            'credit_application_director_info_position_held' : formObj.dirInfoPos,
            'credit_application_final_approval' : {
                'credit_ref' : credRef,         
                'checked_by' : formObj.checkedBy,
                'checked_by_date' : checkedByDate,
                'type_of_account_approved' : codAcc ? 'Cash on Delivery' : 'Account',
                'application_approved' : applAppr,
                'reject_reason' : formObj.envRejectRes,
                'final_approval_name' : formObj.finApprName,
                'final_approval_date' : finApprDate,
                'final_approval_position' : formObj.finApprPos,
                'final_approval_signature' : formObj.finApprSign
            },
            'credit_application_general_credit_items' : {
                'name' : formObj.genCredName,
                'position' : formObj.genCredPos,
                'signature' : formObj.genCredSign,
                'date' : genCredDate 
            },
            'credit_application_credit_privacy_info' : {
                'name' : formObj.credPirName,
                'position' : formObj.credPirPos,
                'signature' : formObj.credPirSign,
                'date' : credPirDate
            },
            'credit_application_gurantee' : {
                'guarantee_1_name' : formObj.gur1Name,
                'gurantee_1_sign'  : formObj.gut1Sign,
                'witness_1_name'    : formObj.wit1Name,
                'witness_1_sign'    : formObj.wit1Sign,
                'witness_1_address' : formObj.wit1Addr,
                'guarantee_2_name' : formObj.gur2Name,
                'gurantee_2_sign'  : formObj.gut2Sign,
                'witness_2_name'    : formObj.wit2Name,
                'witness_2_sign'    : formObj.wit2Sign,
                'witness_2_address' : formObj.wit2Addr
            },
            'credit_application_dated_day' : formObj.dayOf,
            'credit_application_dated_month' : formObj.monthOf,
            'credit_application_dated_year' : formObj.yearOf 
        }


        let str = `<html lang="en"><head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
    </head>
    
    <body style="padding: 10px 10px;">
    
    
        <table style="width: 100%;" border="0">
            <tbody style="width: 100%;">
                <tr style="width: 100%;">
                    <td>
                        <span> <img width="100px" height="auto" src="https://enviro-drive.s3.amazonaws.com/staging/media/uploads/drive/file-archive/My_project.png" alt=""></span>
                    </td>
                    <td style="text-align: center;">
        <p style="font-size: 18px;font-weight: 850;">CREDIT APPLICATION FORM</p>
                        
                    </td>
                    <td>
                        
                        <p style="color: rgb(50, 51, 126); font-size: 10.5px; text-align: right;"> 
                            Lic# 13039- EPA Lic# 20444 -ABN 66 613 987 438 <br>
                            PO BOX 4392 North Rocks NSW 2151 <br>
                            Phone: 1300 141 315 <br>
                            Email: info@envirowaste.com.au <br>
                            www.envirowaste.com.au <br>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <hr>
    
    
     
        
    <p><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;">
            </b></p>
        <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 18pt;"><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Please complete all requested information and send back to </span><a style="text-decoration: none;"><span style="font-size: 11pt; font-family: Arial; color: rgb(5, 99, 193); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: underline; text-decoration-skip: none; vertical-align: baseline; white-space: pre-wrap;">info@envirowaste.com.au</span></a><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> or mail to the address that is stated above.</span></b></p>
        <p><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;">
            </b></p>
        <ol style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;"><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style="font-style: normal;font-variant-caps: normal;letter-spacing: normal;orphans: auto;text-align: start;text-indent: 0px;text-transform: none;widows: auto;word-spacing: 0px;-webkit-text-size-adjust: auto;-webkit-text-stroke-width: 0px;text-decoration: none;caret-color: rgb(0, 0, 0);color: rgb(0, 0, 0);font-weight: normal;">
                <li dir="ltr" aria-level="1" style="list-style-type: upper-roman;font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;/* white-space: pre; */">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 18pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">BUSINESS CONTACT INFORMATION</span></p>
                </li>
            </b></ol>
        <p><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;">
                </b></p><p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style="font-style: normal; font-variant-caps: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-weight: normal;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company Name:&nbsp; ${ formObj.entityName ? formObj.entityName :`____________________`}</span></b></p><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style="font-style: normal;font-variant-caps: normal;letter-spacing: normal;orphans: auto;text-align: start;text-indent: 0px;text-transform: none;white-space: normal;widows: auto;word-spacing: 0px;-webkit-text-size-adjust: auto;-webkit-text-stroke-width: 0px;text-decoration: none;caret-color: rgb(0, 0, 0);color: rgb(0, 0, 0);font-weight: normal;">
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company Address:&nbsp; ${ formObj.entityAddr ? formObj.entityAddr :`____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Company Postcode:&nbsp; ${ formObj.entityPostal ? formObj.entityPostal :`____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">ABN:&nbsp;${ formObj.abn ? formObj.abn :`____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Name:&nbsp; ${ formObj.accConName ? formObj.accConName :`____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Phone:&nbsp; ${ formObj.accConPhone ? formObj.accConPhone :`____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account Contact Email: ${ formObj.accConEmail ? formObj.accConEmail :`____________________`}</span></p>
    
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><span style="font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;white-space: pre-wrap;">Type of Entity:&nbsp; &nbsp; </span>
                    <span style="font-size: 11pt;font-family: Wingdings;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                    ${!formObj.soleTrader ? `&#x2610;`:`&#x2611;`}  
                    </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Sole Trader&nbsp; &nbsp; </span><span style="font-size: 11pt;font-family: Wingdings;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                    ${!formObj.partnership ? `&#x2610;`:`&#x2611;`}  
                    </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Partnership&nbsp; &nbsp; </span><span style="font-size: 11pt;font-family: Wingdings;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                    ${!formObj.privateComp ? `&#x2610;`:`&#x2611;`} 
                    </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Private Company&nbsp; &nbsp; </span><span style="font-size: 11pt;font-family: Wingdings;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                    ${!formObj.indiv ? `&#x2610;`:`&#x2611;`}  
                    </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Individual&nbsp;</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 15pt;padding-inline-start: 56px;"><span style="font-size: 11pt;font-family: Wingdings;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                ${!formObj.oth ? `&#x2610;`:`&#x2611;`}  
                </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Other ${formObj.othExpl ? formObj.othExpl : `____________________`}(type)</span></p>
                <ol start="2" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: upper-roman;font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 18pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">BUSINESS CREDIT REFERENCES</span></p>
                    </li>
                </ol>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: underline; text-decoration-skip: none; vertical-align: baseline; white-space: pre-wrap;">List Three (3) credit references:</span></p><br>
                <ol style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                        <p dir="ltr" role="presentation" style="line-height: 1.2; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;Name:&nbsp; ${bCreditRef[0].bName ? bCreditRef[0].bName : `____________________`}</span></p>
                    </li>
                </ol>
                <p dir="ltr" style="line-height: 1.295;margin-left: 36pt;margin-top: 0pt;margin-bottom: 12pt;padding-inline-start: 14px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;Phone:&nbsp; ${bCreditRef[0].bPhone ? bCreditRef[0].bPhone : `____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-left: 36pt;margin-top: 0pt;margin-bottom: 12pt;padding-inline-start: 14px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;Email:&nbsp; ${bCreditRef[0].bEmail ? bCreditRef[0].bEmail : `____________________`}</span></p><br>
                <ol start="2" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                        <p dir="ltr" role="presentation" style="line-height: 1.2; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;Name:&nbsp; ${bCreditRef[1].bName ? bCreditRef[1].bName : `____________________`}</span></p>
                    </li>
                </ol>
                <p dir="ltr" style="line-height: 1.2;margin-left: 36pt;margin-top: 0pt;margin-bottom: 12pt;padding-inline-start: 14px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;Phone:&nbsp; ${bCreditRef[1].bPhone ? bCreditRef[1].bPhone : `____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.2;margin-left: 18pt;text-indent: 18pt;margin-top: 0pt;margin-bottom: 12pt;padding-inline-start: 14px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;Email:&nbsp; ${bCreditRef[1].bEmail ? bCreditRef[1].bEmail : `____________________`}</span></p><br>
                <ol start="3" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                        <p dir="ltr" role="presentation" style="line-height: 1.2; margin-top: 0pt; margin-bottom: 12pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name:&nbsp; ${bCreditRef[2].bName ? bCreditRef[2].bName : `____________________`}</span></p>
                    </li>
                </ol>
                <p dir="ltr" style="line-height: 1.2;margin-left: 36pt;margin-top: 0pt;margin-bottom: 12pt;padding-inline-start: 14px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;Phone:&nbsp; ${bCreditRef[2].bPhone ? bCreditRef[2].bPhone : `____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.2;margin-left: 18pt;text-indent: 18pt;margin-top: 0pt;margin-bottom: 12pt;padding-inline-start: 14px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;Email:&nbsp; ${bCreditRef[2].bEmail ? bCreditRef[2].bEmail : `____________________`}</span></p><br>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 48px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: underline; text-decoration-skip: none; vertical-align: baseline; white-space: pre-wrap;">Director/s Information:</span></p>
                <div dir="ltr" align="left" style="margin-left: 30pt;">
                    <table style="border: none; border-collapse: collapse;">
                        <colgroup>
                            <col width="319">
                            <col width="319">
                        </colgroup>
                        <tbody>
                            <tr style="height: 28.05pt;page-break-inside: avoid;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0); vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
                                    <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name: &nbsp;&nbsp;${ dirInfo[0].dName ? dirInfo[0].dName :`____________________`}</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0); vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
                                    <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name: &nbsp;&nbsp;${ dirInfo[1].dName ? dirInfo[1].dName : `____________________`}</span></p>
                                </td>
                            </tr>
                            <tr style="height: 28.05pt;page-break-inside: avoid;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0); vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
                                    <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Address: &nbsp;&nbsp;${ dirInfo[0].dAddr ? dirInfo[0].dAddr :`____________________`}</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0); vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
                                    <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Address: &nbsp;&nbsp;${ dirInfo[1].dAddr ? dirInfo[1].dAddr :`____________________`}</span></p>
                                </td>
                            </tr>
                            <tr style="height: 28.05pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0); vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
                                    <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Phone: &nbsp;&nbsp;${ dirInfo[0].dPhone ? dirInfo[0].dPhone :`____________________`}</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0); vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
                                    <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Phone: &nbsp;&nbsp;${ dirInfo[1].dPhone ? dirInfo[1].dPhone:`____________________`}</span></p>
                                </td>
                            </tr>
                            <tr style="height: 28.05pt;page-break-inside: avoid;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0); vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
                                    <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Email: &nbsp;&nbsp;${ dirInfo[0].dEmail ? dirInfo[0].dEmail :`____________________`}</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0); vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
                                    <p dir="ltr" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Email: &nbsp;&nbsp;${ dirInfo[1].dEmail ? dirInfo[1].dEmail :`____________________`}</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div><br>
                <p dir="ltr" style="line-height: 1.2;text-align: justify;margin-top: 8pt;margin-bottom: 0pt;padding-inline-start: 48px;"><span style="font-size: 9pt; font-family: Verdana; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Signed: &nbsp;
                ${ formObj.dirInfoSign ? `<img src=${formObj.dirInfoSign} style="margin-left:100px;margin-top:0px;" width="200" height="100">`
                    :`____________________`}(Print)</span>
                <span style="font-size: 9pt; font-family: Verdana; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"></span><span style="font-size: 9pt; font-family: Verdana; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name:&nbsp;&nbsp;${ formObj.dirInfoName ? formObj.dirInfoName :`____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.2;text-align: justify;margin-top: 8pt;margin-bottom: 0pt;padding-inline-start: 48px;"><span style="font-size: 9pt; font-family: Verdana; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Date: ${ dayjs(dirInfoDate).isValid() ? dayjs(dirInfoDate).format('DD/MMMM/YYYY') :`____________________`}</span></p><br>
                <p dir="ltr" style="line-height: 1.7999999999999998;margin-top: 0pt;margin-bottom: 0pt;padding-inline-start: 48px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">By signing this CREDIT APPLICATION FORM,&nbsp;</span></p>
                <ul style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: disc;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -0.5499999999999972pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.7999999999999998; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I permit that all information provided in this application form is correct.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: disc;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -0.5499999999999972pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.7999999999999998; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I certify that I am authorised to sign this application for and on behalf of all directors/partners owners of the Applicant in my capacity as&nbsp; ${formObj.dirInfoPos ? formObj.dirInfoPos :`____________________`} (Position Held ).</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: disc;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -0.5499999999999972pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.7999999999999998; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I certify that I have read, understood, and agreed to all Terms and Conditions stated below as part of this credit application.&nbsp;</span></p>
                    </li>
                </ul><br>
                <ol start="3" style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: upper-roman;font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                        <p dir="ltr" role="presentation" style="line-height: 1.2; margin-top: 6pt; margin-bottom: 12pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">ENVIRO WASTE SERVICES GROUP FINAL APPROVAL</span></p>
                    </li>
                </ol>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Office Use Only:</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 64px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Have the Credit References checked out?&nbsp; &nbsp; &nbsp; &nbsp; YES-${credRef ? `&#x2611;`:`&#x2610;`}  </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> &nbsp;  &nbsp; </span>
                <span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"></span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">NO-${!credRef ? `&#x2611;`:`&#x2610;`}  &nbsp;</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 64px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Checked by:  ${formObj.checkedBy ? formObj.checkedBy : `____________________`}&nbsp;&nbsp;  Date: ${ dayjs(checkedByDate).isValid()? dayjs(checkedByDate).format('DD/MMMM/YYYY') :`____________________`}</span></p><br>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 64px;"><span style="font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;white-space: pre-wrap;">Type of account approved: &nbsp;  &nbsp; </span><span style="font-size: 11pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;white-space: pre-wrap;"></span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Cash on Delivery &nbsp; ${codAcc ? `&#x2611;`:`&#x2610;`}   &nbsp;  &nbsp; </span>
                <span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"></span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"></span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Account:&nbsp;${!codAcc ? `&#x2611;`:`&#x2610;`}  </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p><br>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Application is being approved.&nbsp; &nbsp; YES-${applAppr ? `&#x2611;`:`&#x2610;`}  </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> &nbsp;  &nbsp; </span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"></span><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">NO-${!credRef ? `&#x2611;`:`&#x2610;`}  </span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If application is rejected, please state the reason(s):</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${formObj.envRejectRes ? formObj.envRejectRes :`____________________`}</span></p><br>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Final Approval</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name:${ formObj.finApprName ? formObj.finApprName :`____________________`}&nbsp; Date:${dayjs(finApprDate).isValid() ? dayjs(finApprDate).format('DD/MMMM/YYYY') :`____________________`}&nbsp; Position: ${ formObj.finApprPos ?formObj.finApprPos :`____________________`}</span></p><br>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 56px;"><span style="font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Signature:&nbsp; 
                ${formObj.finApprSign ? `<img src=${formObj.finApprSign} style="margin-left:100px;margin-top:0px;" width="200" height="100">`
                    :`____________________`}</span></p><br>
                <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">GENERAL CREDIT TERMS</span></p><br>
                <ol style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;padding-bottom: 10px;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Payment:</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> The Customer must pay for all Products &amp; Services supplied by Enviro Waste Services Group Pty Ltd within any credit period granted in writing by Enviro Waste Services Group Pty Ltd.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(a) Interest</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">: Enviro Waste Services Group Pty Ltd is entitled to charge the Customer interest on amounts not paid within the specified credit period at a rate equivalent to the reference rate charged by Enviro Waste Services Group Pty Ltd principal debt collectors, from invoice date until payment of the debt.</span></p>
                    </li>
                </ol>
                <p dir="ltr" style="line-height: 1.2;margin-left: 36pt;margin-top: 0pt;margin-bottom: 0pt;padding-bottom: 10px;"><span style="font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;white-space: pre-wrap;">(b) Should it become necessary for Enviro Waste Services Group Pty Ltd to institute legal action for recovery of any amounts due to it by the Customer, the Customer specifically acknowledges and agrees that it shall be liable to Enviro Waste Services Group Pty Ltd on demand for all costs incurred by Enviro Waste Services Group Pty Ltd in recovery of such amounts, including all costs on a full indemnity basis.</span></p>
                <ol start="3" style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;padding-bottom: 10px;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">GST</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">: Each amount payable by the Customer under these Terms in respect of a Taxable Supply by Enviro Waste Services Group Pty Ltd is a GST exclusive amount and on receipt of a tax invoice the Customer must, in addition to the amount and at the same time, pay the GST payable in respect of that supply. “Taxable Supply”, and “GST” have the meanings set out in the A New Tax System (Goods and Services) Act 1999.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;padding-bottom: 10px;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Withdrawal</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> or Variation of Credit: Enviro Waste Services Group Pty Ltd may at any time, without the need to provide a reason, vary or withdraw any credit granted to the Customer.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;padding-bottom: 10px;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Disputes:</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Disputed invoices must be notified to Enviro Waste Services Group Pty Ltd within 14 days of delivery of the invoice by Enviro Waste Services Group Pty Ltd to the Customer, failing which Enviro Waste Services Group Pty Ltd will have no further liability in relation to the matters in dispute.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;padding-bottom: 10px;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Enviro Waste Services Group Pty Ltd</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> will rely on the information the Customer provides in respect of the Site. The Customer must ensure that there is unobstructed and safe access for Enviro Waste Services Group Pty Ltd to perform the services. If through no fault of Enviro Waste Services Group Pty Ltd, Enviro Waste Services Group Pty Ltd is delayed or experiences obstruction on Site, a charge of Enviro Waste Services Group Pty Ltd minimum charge applies.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;padding-bottom: 10px;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The Customer warrants and agrees</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> that the ground surfaces traversed by Enviro Waste Services Group Pty Ltd service vehicles in connection with the performance of the Services will at all times be suitable for that purpose. The Customer indemnifies Enviro Waste Services Group Pty Ltd from and against any liabilities, claims, damages, actions, costs and expenses as a result of or arising out of any breach by the Customer of any of the provisions and warranties set out in this clause.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;padding-bottom: 10px;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The Customer is solely responsible</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> for obtaining permission from relevant person, entity or authority (i.e Council, Body Corporate/Strata Manager) in which the circumstances requires the permission of a third party.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;padding-bottom: 10px;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The Customer is obligated</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> to supply the product as described in the Quote issued by Enviro Waste Services Group Pty Ltd representatives, any variations to product description must be authorised in writing by an Enviro Waste Services Group Pty Ltd representative prior to job completion. The Customer is obligated to supply a MSDS (Material Safety Data Sheet) upon request. If Customer is unable to provide a MSDS (Material Safety Data Sheet), Enviro Waste Services Group Pty Ltd may at anytime perform an Analysis at an accredited laboratory on the Customer’s product to determine the acceptance criteria. The cost for Analysis will be at the Customer’s expense.&nbsp;&nbsp;</span></p>
                    </li>
                </ol>
                <p dir="ltr" style="line-height: 1.295;margin-left: 25pt;margin-top: 0pt;margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I/We agree to be bound by the General Credit Terms set out above and by the terms set out in this application and warrant that the information given by me/us in this application is true and accurate.</span></p><br>
                <p dir="ltr" style="line-height: 1.295;margin-left: 30pt;margin-top: 0pt;margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name: ${ formObj.genCredName? formObj.genCredName :`____________________`} &nbsp;&nbsp;Position: ${ formObj.genCredPos ? formObj.genCredPos :`____________________`}</span></p><br>
                <p dir="ltr" style="line-height: 1.295;margin-left: 30pt;margin-top: 0pt;margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Signature: 
                ${ formObj.genCredSign ? `<img src=${formObj.genCredSign} style="margin-left:100px;margin-top:0px;" width="200" height="100">`
                    :`____________________`} Date: ${ dayjs(genCredDate).isValid() ? dayjs(genCredDate).format('DD/MMMM/YYYY') :`____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-left: 30pt;margin-top: 0pt;margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: italic; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(Signed for and on behalf of the Customer)</span></p><br>
                <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">CREDIT AND PRIVACY INFORMATION</span></p><br>
                <ol style="margin-top: 0px;margin-bottom: 1px;padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I/We consent and authorise Enviro Waste Services Group Pty Ltd</span></p>
                    </li>
                </ol>
                <ol style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">To request a credit report containing information about my/our consumer or commercial credit arrangements from a credit agency for the purposes of assessing this application or in connection with the attached Guarantee and indemnity.&nbsp;</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">To give a credit reporting agency information to allow the credit reporting agency to create and maintain a credit information file containing information about me/us.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">To disclose a credit report or any personal information derived from the credit report, and any information about my/our personal or commercial credit arrangements to Enviro Waste Services Group Pty Ltd, a related body corporate of Enviro Waste Services Group Pty Ltd, any agent of Enviro Waste Services Group Pty Ltd assisting in processing the application and any other provider of credit to me/us named in this application in a credit report from a credit reporting agency; and</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">To notify and exchange information with other credit providers and any collection agent of Enviro Waste Services Group Pty Ltd or any of its bodies corporate.</span></p>
                    </li>
                </ol>
                <ol start="2" style="margin-top: 0px;margin-bottom: 1px;padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I/We understand that the information permitted to be disclosed under the Privacy Act 1988 (Cth) includes:</span></p>
                    </li>
                </ol>
                <ol style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Details to identify me/us – that is name, sex, date of birth, current and 2 previous addresses, current or last known employer, and driver’s licence number.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The fact that I/we have applied for credit, the amount, or that Enviro Waste Services Group Pty Ltd is a current credit provider to me/us.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Advice that payments previously notified as unpaid are no longer overdue.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Payments overdue for at least 60 days and for which collection action has started.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Cheques for more than $100 drawn by me/us which have been dishonored more than once.</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">In specified circumstances, that in the opinion of Enviro Waste Services Group Pty Ltd I/we have committed a serious credit infringement; and&nbsp;</span></p>
                    </li>
                    <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: 18pt;margin-bottom: 6pt;">
                        <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The fact that credit provided to me/us by Enviro Waste Services Group Pty Ltd has been paid or otherwise discharged.</span></p>
                    </li>
                </ol><br>
                <p dir="ltr" style="line-height: 1.295; text-indent: 18pt; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Name: ${ formObj.credPirName ? formObj.credPirName :  `____________________`}&nbsp;&nbsp; Position: ${ formObj.credPirPos ? formObj.credPirPos : `____________________`}</span></p><br>
                <p dir="ltr" style="line-height: 1.295; margin-left: 18pt; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Signature: ${ formObj.credPirSign ? `<img src=${formObj.credPirSign} style="margin-left:100px;margin-top:0px;" width="200" height="100">` 
                : `____________________`}&nbsp;&nbsp; Date: ${dayjs(credPirDate).isValid() ? dayjs(credPirDate).format('DD/MMMM/YYYY') : `____________________`}</span></p>
            <p dir="ltr" style="line-height: 1.295; margin-left: 18pt; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: italic; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(Signed for and on behalf of the Customer)</span></p><br><br><br><br><br><br><br><br>
            <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 12pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">GUARANTEE AND INDEMNITY</span></p>
            <ol style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Guarantee</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The Guarantor unconditionally and irrevocably guarantees to Enviro Waste Services Group Pty Ltd the due and punctual payment of the Guaranteed Moneys and agrees:</span></p>
            <ol style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 48px;margin-left: 28px;">
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 5px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">On demand from time to time to pay an amount equal to the Guaranteed Moneys then due and payable.</span></p>
                </li>
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 5px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Any statement signed by an Enviro Waste Services Group Pty Ltd director, secretary, credit manager or authoroised representative certifying the amount of Guaranteed Moneys or the money owing by the Guarantor under his Guarantee is, in the absence of manifest error, binding and conclusive on and against the Guarantor.</span></p>
                </li>
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 5px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">This Guarantee is a continuing guarantee and remains in full force and effect until all the Guaranteed Moneys are paid or satisfied in full and is in addition to, and not prejudiced or affected by any other security or guarantee held by Enviro Waste Services Group Pty Ltd for the payment of Guaranteed Moneys.</span></p>
                </li>
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 5px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The liabilities of the Guarantor and the rights of Enviro Waste Services Group Pty Ltd under this Guarantee are not affected by anything which might otherwise affect them at law or in equity; and</span></p>
                </li>
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If any payment by the Guarantor under this Guarantee or the Customer is avoided, set aside, ordered to be refunded or reduced rendered unenforceable by any laws relating to bankruptcy, insolvency or liquidation, that payment will be taken not to have been made and Enviro Waste Services Group Pty Ltd is entitled to recover from the Guarantor the value of that payment as if that payment had never been made. This clause continues after this Guarantee is discharged.&nbsp;</span></p>
                </li>
            </ol><br>
            <ol start="2" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Indemnity&nbsp;</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If the obligation of the Customer to pay the Guaranteed Moneys to Enviro Waste Services Group Pty Ltd is unenforceable for any reason, the Guarantor as a separate undertaking unconditionally and irrevocably indemnifies Enviro Waste Services Group Pty Ltd against any loss Enviro Waste Services Group Pty Ltd suffers as a result. Enviro Waste Services Group Pty Ltd need not incur any expense or make any payment before enforcing this right of indemnity.</span></p>
            <ol start="3" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Waiver</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">No failure or delay by Enviro Waste Services Group Pty Ltd to exercise any power, right or remedy under this Guarantee operates as a waiver, nor does any single or partial exercise of any power, right or remedy preclude any other or further exercise of that power, right or remedy. Enviro Waste Services Group Pty Ltd rights and remedies under this Guarantee are in addition to any rights and remedies arising at law.</span></p>
            <ol start="4" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Claim in Administration&nbsp;</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Until this Guarantee is released by Enviro Waste Services Group Pty Ltd, the Guarantor will not without Enviro Waste Services Group Pty Ltd consent, prove in any Administration of the Customer in competition with Enviro Waste Services Group Pty Ltd or any related body corporate of Enviro Waste Services Group Pty Ltd.</span></p>
            <ol start="5" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Continuing Guarantee&nbsp;</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Any guarantee shall remain in force so long as the Customer shall maintain an account with Enviro Waste Services Group Pty Ltd. All guarantees shall be continuing guarantees and will terminate only in writing from Enviro Waste Services Group Pty Ltd.</span></p>
            <ol start="6" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Application of Moneys Received</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If Enviro Waste Services Group Pty Ltd receives or recovers money in respect of debts of the Customer or anyone else, Enviro Waste Services Group Pty Ltd may use it to pay off whichever part of those debts it chooses and does not have to apply it for the Guarantor’s benefit.</span></p><br>
            <ol start="7" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Change Over Guarantor’s Assets&nbsp;</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As security for payment to Enviro Waste Services Group Pty Ltd of the Guaranteed Moneys and for its obligations generally under this Guarantee, the Guarantor charges in favour of Enviro Waste Services Group Pty Ltd the whole of the Guarantor’s undertaking, property and assets (including, without limitation, all of the Guarantor’s interests, both legal and beneficial, in freehold and leasehold land and any assets listed in this application as Security Interest Property) both current and later acquired. Enviro Waste Services Group Pty Ltd may register a caveat on title or otherwise take such action as it deems appropriate to protect or enforce its interest in the security granted.&nbsp;</span></p>
            <ol start="8" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Trusts</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">This document binds the Guarantor both personally and as trustee of any trust of which the Guarantor is a trustee.</span></p>
            <ol start="9" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Acknowledgement</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;text-indent: 36pt;margin-top: 0pt;margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The Guarantor acknowledges the Guarantor:</span></p>
            <ol style="margin-top: 0px;margin-bottom: 0px;margin-left: 60px;padding-inline-start: 48px;">
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 1px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Has entered into this agreement voluntarily.</span></p>
                </li>
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 1px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Has read and understood the nature and consequences of entering into this Guarantee;</span></p>
                </li>
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 1px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Has not signed this Guarantee on the basis of any representation of Enviro Waste Services Group Pty Ltd, its employees, agents or representatives or under the duress of any person;</span></p>
                </li>
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 1px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Is entitled to see independent legal advice before signing this Guarantee; and</span></p>
                </li>
                <li dir="ltr" aria-level="1" style="list-style-type: lower-alpha;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;margin-bottom: 1px;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Submits to the non-exclusive jurisdiction of the courts of the State in which this Guarantee is received by Enviro Waste Services Group Pty Ltd and that the law applying in that State is the proper law governing this Guarantee.</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
                </li>
            </ol>
            <ol start="10" style="margin-top: 0px;margin-bottom: 0px;padding-inline-start: 64px;">
                <li dir="ltr" aria-level="1" style="list-style-type: decimal;font-size: 10pt;font-family: Arial;color: rgb(0, 0, 0);background-color: transparent;font-weight: 400;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-variant-east-asian: normal;font-variant-position: normal;text-decoration: none;vertical-align: baseline;margin-left: -21.8pt;">
                    <p dir="ltr" role="presentation" style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Severance&nbsp;</span></p>
                </li>
            </ol>
            <p dir="ltr" style="line-height: 1.295;margin-left: 14.2pt;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Each Clause and subclause of this Guarantee is separate and independent. If any clause or subclause is found to be invalid or ineffective. The other clauses or subclauses or parts with not be adversely affected.</span></p><br>
            <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I have read and understood this document. I have not relied on anything said to me by the Customer or Enviro Waste Services Group Pty Ltd as to what it means or what its effects may be.</span></p>
            <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 8pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">SIGNED by each Guarantor in the presence of the witnesses whose names appear below.</span></p><br>
            <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 18pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Guarantor’s Name:</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"></span>
        <span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${formObj.gur1Name ? formObj.gur1Name : `____________________`}&nbsp;&nbsp; Guarantor’s Signature: 
        ${formObj.gut1Sign ? `<img src=${formObj.gut1Sign} style="margin-left:100px;margin-top:0px;" width="200" height="100">` 
            : `____________________`}</span></p>
            <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 18pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Witness Name:  ${formObj.wit1Name ? formObj.wit1Name : `____________________`}&nbsp; Witness Signature: 
            ${formObj.wit1Sign ? `<img src=${formObj.wit1Sign} style="margin-left:100px;margin-top:0px;" width="200" height="100">`
                : `____________________`}</span></p>
            <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 18pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Witness Address: ${ formObj.wit1Addr ? formObj.wit1Addr : `____________________`}</span></p>
            <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 18pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Guarantor’s Name:</span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"></span>
        <span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${formObj.gur2Name ? formObj.gur2Name : `____________________`}&nbsp;&nbsp; Guarantor’s Signature: 
        ${formObj.gut2Sign ? `<img src=${formObj.gut2Sign} style="margin-left:100px;margin-top:0px;" width="200" height="100">`
            : `____________________`}</span></p>
            <p dir="ltr" style="line-height: 1.295; margin-left: 14.2pt; margin-top: 0pt; margin-bottom: 18pt;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Witness Name: ${formObj.wit2Name ? formObj.wit2Name : `____________________`}&nbsp; Witness Signature: 
            ${formObj.wit2Sign ? `<img src=${formObj.wit2Sign} style="margin-left:100px;margin-top:0px;" width="200" height="100">`
                : `____________________`}</span></p>
                <p dir="ltr" style="line-height: 1.295;margin-top: 0pt;margin-bottom: 8pt;padding-inline-start: 14px;"><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">DATED </span><span style="font-size: 10pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">this ${formObj.dayOf ? formObj.dayOf :` ____` }&nbsp;&nbsp;day of &nbsp;&nbsp;${formObj.monthOf ? formObj.monthOf : `____________________`}, 20 ${formObj.yearOf ? formObj.yearOf : `___`}</span></p>
            </b><br class="Apple-interchange-newline" style="font-style: normal; font-variant-caps: normal; font-weight: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0);"><br class="Apple-interchange-newline" style="caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-style: normal; font-variant-caps: normal; font-weight: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none;"><p></p>
        <p></p>
        <p></p>
    
    
    </body></html>
    ${Object.entries(formObj).some((_,v)=> (v!==''&& typeof v!=='boolean')) 
    ? `<!--DATAENTERED-->` : ``}`

    editAction(str,tempObj,templateName)

    // setTemplateStr(str,)
    setModalOpen(false)
    //console.log(str)

    }

    
    return (
        <div className={ modalOpen ? "quoteModalCloseBx folderModalBg" : "folderModalBg"}>
            <form className="folderModalContent">
            <HighlightOffRoundedIcon style={{ fontSize:'25px', color:'blue', float:'right' }} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); setModalOpen(false);}}/>

                {/* <p>Please complete all requested information and send back to info@envirowaste.com.au or mail to the address that is stated above.</p> */}
                <Scrollbars style={{ height:'65vh' }}> 
                <div style={{ display:'flex', flexDirection:'column', padding:'20px' }}>

                
                <p><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" 
                style={{fontStyle: 'normal',
                fontVariantCaps: 'normal',
                letterSpacing: 'normal',
                orphans: 'auto',
                textAlign: 'start',
                textIndent: '0px',
                textTransform: 'none',
                whiteSpace: 'normal',
                widows: 'auto',
                wordSpacing: '0px',
                webkitTextSizeAdjust: 'auto',
                webkitTextStrokeWidth: '0px',
                textDecoration: 'none',
                caretColor: 'rgb(0 0 0)',
                color: 'rgb(0 0 0)',
                fontWeight: 'normal'}}>
                </b><br /></p>

<p dir="ltr" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '18pt'}}><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style={{fontStyle: 'normal',fontVariantCaps: 'normal',letterSpacing: 'normal',orphans: 'auto',textAlign: 'start',textIndent: '0px',textTransform: 'none',whiteSpace: 'normal',widows: 'auto',wordSpacing: '0px',webkitTextSizeAdjust: 'auto',webkitTextStrokeWidth: '0px',textDecoration: 'none',caretColor: 'rgb(0 0 0)',color: 'rgb(0 0 0)',fontWeight: 'normal',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Please complete all requested information and send back to </span><a href="info@envirowaste.com.au" style={{textDecoration: 'none'}}>
    <span style={{fontSize: '11pt', fontFamily: 'Arial', color: 'rgb(5, 99, 193)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'underline', textDecorationSkip: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>info@envirowaste.com.au</span></a><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}> or mail to the address that is stated above.</span></b></p>
<p><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style={{fontStyle: 'normal',fontVariantCaps: 'normal',letterSpacing: 'normal',orphans: 'auto',textAlign: 'start',textIndent: '0px',textTransform: 'none',whiteSpace: 'normal',widows: 'auto',wordSpacing: '0px',webkitTextSizeAdjust: 'auto',webkitTextStrokeWidth: '0px',textDecoration: 'none',caretColor: 'rgb(0 0 0)',color: 'rgb(0 0 0)',fontWeight: 'normal',}}>
    </b><br /></p>
<ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}><b id="docs-internal-guid-1a718b81-7fff-8bc0-4bf5-7d37bb08e4ca" style={{fontStyle: 'normal',fontVariantCaps: 'normal',letterSpacing: 'normal',orphans: 'auto',textAlign: 'start',textIndent: '0px',textTransform: 'none',whiteSpace: 'normal',widows: 'auto',wordSpacing: '0px',webkitTextSizeAdjust: 'auto',webkitTextStrokeWidth: '0px',textDecoration: 'none',caretColor: 'rgb(0 0 0)',color: 'rgb(0 0 0)',fontWeight: 'normal',}}>
        <li dir="ltr" aria-level="1" style={{listStyleType: 'upper-roman',
fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre'}}>
            <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '18pt'}}><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>BUSINESS CONTACT INFORMATION</span></p>
        </li>
    </b></ol>

        <p dir="ltr" style={{lineHeight: '1.295',
paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '15pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Company Name:&nbsp;
                                
                                
                                    <input type="text" value={formObj.entityName} onChange={(e)=>setFormObj({...formObj, entityName : e.target.value})}/>
                                

</span></p><p dir="ltr" style={{lineHeight: '1.295',

paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '15pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Company Address:&nbsp;
                                
                                
                                    <input type="text" value={formObj.entityAddr} onChange={(e)=>setFormObj({...formObj, entityAddr : e.target.value})}/>
                                

</span></p><p dir="ltr" style={{lineHeight: '1.295',

paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '15pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Company Postal Address:&nbsp;

                                
                                    <input type="text" value={formObj.entityPostal} onChange={(e)=>setFormObj({...formObj, entityPostal : e.target.value})}/>
                                

</span></p><p dir="ltr" style={{lineHeight: '1.295',

paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '15pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>ABN:&nbsp; &nbsp;
                                
                                
                                    <input type="text" value={formObj.abn} onChange={(e)=>setFormObj({...formObj, abn : e.target.value})}/>
                                

{/* &nbsp; Fax: &nbsp; */}

                                
                                    {/* <input type="text" value={formObj.fax} onChange={(e)=>setFormObj({...formObj, fax : e.target.value})}/> */}
                                

</span></p><p dir="ltr" style={{lineHeight: '1.295',

paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '15pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Account Contact Name:
                                
                                
                                    <input type="text" style={{ marginRight: '48px' }} value={formObj.accConName} onChange={(e)=>setFormObj({...formObj, accConName : e.target.value})}/>
                                

Account Contact Email :

                                
                                    <input type="text" value={formObj.accConEmail} onChange={(e)=>{
                                        if(pattern.test(e.target.value))
                                            setFormObj({...formObj, accConEmail : e.target.value, errEmail : false})
                                        else 
                                            setFormObj({...formObj, accConEmail : e.target.value, errEmail : true})
                                    }} />
                                        {
                                            formObj.errEmail ?
                                            <span style={{ color:'red' }}>Please Enter a valid Email</span>
                                            :   null
                                        }
                            
</span></p><p dir="ltr" style={{lineHeight: '1.295',

paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '15pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Accounts Contact Phone:
                                
                                
                                    <input type="text" value={formObj.accConPhone} onChange={(e)=>setFormObj({...formObj, accConPhone : e.target.value})}/>
                                

</span></p><p dir="ltr" style={{lineHeight: '1.295',

paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '15pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Type of Entity:&nbsp; &nbsp; </span>
                                
    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap'}}>
        <div style={{ display:'flex', flexDirection:'row', padding:'0 10px', alignItems:'center', justifyContent:'center' }}>
                                    <input type="checkbox" value={formObj.soleTrader} onChange={()=>setFormObj({...formObj, soleTrader : !formObj.soleTrader})}/>
                                    <p style={{ paddingLeft:'20px' }}>Sole Trader</p>
        </div>                        
        <div style={{ display:'flex', flexDirection:'row', padding:'0 10px', alignItems:'center', justifyContent:'center' }}>                  
                                    <input type="checkbox" value={formObj.partnership} onChange={()=>setFormObj({...formObj, partnership : !formObj.partnership})}/>
                                    <p style={{ paddingLeft:'20px' }}>Partnership</p>
                                    </div>                        
        <div style={{ display:'flex', flexDirection:'row', padding:'0 10px', alignItems:'center', justifyContent:'center' }}>                                           
                                    <input type="checkbox" value={formObj.privateComp} onChange={()=>setFormObj({...formObj, privateComp : !formObj.privateComp})}/>
                                    <p style={{ paddingLeft:'20px' }}>Private Company</p>
                                    </div>                        
        <div style={{ display:'flex', flexDirection:'row', padding:'0 10px', alignItems:'center', justifyContent:'center' }}>                        
                                
                                    <input type="checkbox" value={formObj.indiv} onChange={()=>setFormObj({...formObj, indiv : !formObj.indiv})}/>
                                    <p style={{ paddingLeft:'20px' }}>Individual</p>
                                    </div>                        
        <div style={{ display:'flex', flexDirection:'row', padding:'0 10px', alignItems:'center', justifyContent:'center' }}>                        
                                
                                    <input type="checkbox" value={formObj.oth} onChange={()=>setFormObj({...formObj, oth : !formObj.oth})}/>
                                    <p style={{ paddingLeft:'20px' }}>Other</p>
                                    <input type="text"  value={formObj.othExpl} onChange={(e)=>setFormObj({...formObj, othExpl : e.target.value})}/>
                                    (type)
        </div>
    </div>                            
</p>
<ol start="2" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
<li dir="ltr" aria-level="1" style={{listStyleType: 'upper-roman',
fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre'}}>
<p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '18pt'}}><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>BUSINESS CREDIT REFERENCES</span></p>
</li>
</ol>
<p dir="ltr" style={{lineHeight: '1.295',
paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'underline',
textDecorationSkip: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>List Three (3) credit references:</span></p><br />
<ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
<li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre'}}>
<p dir="ltr" role="presentation" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;Name:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[0].bName} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[0].bName = e.target.value
                                                    setBcreditRef([...bCreditRef, temp]);
                                                }} />
                                            

</span></p></li></ol>
<p dir="ltr" style={{lineHeight: '1.295', marginLeft: '36pt', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;Phone:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[0].bPhone} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[0].bPhone = e.target.value
                                                    setBcreditRef([...bCreditRef, temp])}} />
                                            

</span></p><p dir="ltr" style={{lineHeight: '1.295', marginLeft: '36pt', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;Email:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[0].bEmail} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[0].bEmail = e.target.value
                                                    if(pattern.test(e.target.value))
                                                        temp[0].bErrEmail = false 
                                                    else 
                                                        temp[0].bErrEmail = true
                                                    setBcreditRef([...bCreditRef, temp])}} />
                                                {
                                            bCreditRef[0].bErrEmail ?
                                            <span style={{ color:'red' }}>Please Enter a valid Email</span>
                                            :   null
                                        }
                            

</span></p><br /><ol start="2" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
<li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre'}}>
<p dir="ltr" role="presentation" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;Name:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[1].bName} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[1].bName = e.target.value
                                                    setBcreditRef([...bCreditRef, temp])}} />
                                            

</span></p></li></ol>
<p dir="ltr" style={{lineHeight: '1.2', marginLeft: '36pt', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;Phone:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[1].bPhone} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[1].bPhone = e.target.value
                                                    setBcreditRef([...bCreditRef, temp])}} />
                                            

</span></p><p dir="ltr" style={{lineHeight: '1.2', marginLeft: '18pt', textIndent: '18pt', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;Email:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[1].bEmail} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[1].bEmail = e.target.value
                                                    if(pattern.test(e.target.value))
                                                        temp[1].bErrEmail = false 
                                                    else 
                                                        temp[1].bErrEmail = true
                                                    setBcreditRef([...bCreditRef, temp])}} />
                                            {
                                            bCreditRef[1].bErrEmail ?
                                            <span style={{ color:'red' }}>Please Enter a valid Email</span>
                                            :   null
                                        }

</span></p><br /><ol start="3" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
<li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre'}}>
<p dir="ltr" role="presentation" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Name:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[2].bName} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[2].bName = e.target.value
                                                    setBcreditRef([...bCreditRef, temp])}} />
                                            

</span></p></li></ol>
<p dir="ltr" style={{lineHeight: '1.2', marginLeft: '36pt', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;Phone:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[2].bPhone} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[2].bPhone = e.target.value
                                                    setBcreditRef([...bCreditRef, temp])}} />
                                            

</span></p><p dir="ltr" style={{lineHeight: '1.2', marginLeft: '18pt', textIndent: '18pt', marginTop: '0pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;Email:&nbsp;
                                            
                                            
                                                <input type="text" value={bCreditRef[2].bEmail} 
                                                onChange={(e)=>{ 
                                                    let temp = bCreditRef; 
                                                    temp[2].bEmail = e.target.value
                                                    if(pattern.test(e.target.value))
                                                        temp[2].bErrEmail = false 
                                                    else 
                                                        temp[2].bErrEmail = true
                                                    setBcreditRef([...bCreditRef, temp])}} />
                                            {
                                            bCreditRef[2].bErrEmail ?
                                            <span style={{ color:'red' }}>Please Enter a valid Email</span>
                                            :   null
                                        }

</span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295',
        paddingInlineStart: '48px',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'underline',
textDecorationSkip: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Director/s Information:</span></p>
        <div dir="ltr" align="left" style={{marginLeft: '25pt'}}>
            <table style={{border: 'none', borderCollapse: 'collapse'}}>
                <colgroup>
                    <col width="319" />
                    <col width="319" />
                </colgroup>
                <tbody>
                    <tr style={{height: '28.05pt'}}>
                        <td style={{border: '0.5pt solid rgb(0, 0, 0)', verticalAlign: 'top', padding: '0pt 5.4pt', overflow: 'hidden', overflowWrap: 'break-word'}}>
                            <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Name:</span></p>
                                                
                                                
                                                    <input type="text" value={dirInfo[0].dName} 
                                                onChange={(e)=>{ 
                                                    let temp = dirInfo; 
                                                    temp[0].dName = e.target.value
                                                    setDirInfo([...dirInfo, temp])}}  />
                                                

</td>
<td style={{border: '0.5pt solid rgb(0, 0, 0)', verticalAlign: 'top', padding: '0pt 5.4pt', overflow: 'hidden', overflowWrap: 'break-word'}}>
    <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Name:</span></p>
                                                
                                                
                                                    <input type="text"  value={dirInfo[1].dName} 
                                                onChange={(e)=>{ 
                                                    let temp = dirInfo; 
                                                    temp[1].dName = e.target.value
                                                    setDirInfo([...dirInfo, temp])}}/>
                                                

</td></tr>
<tr style={{height: '28.05pt'}}>
    <td style={{border: '0.5pt solid rgb(0, 0, 0)', verticalAlign: 'top', padding: '0pt 5.4pt', overflow: 'hidden', overflowWrap: 'break-word'}}>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Address:</span></p>
                                                
                                                
                                                    <input type="text" value={dirInfo[0].dAddr} 
                                                onChange={(e)=>{ 
                                                    let temp = dirInfo; 
                                                    temp[0].dAddr = e.target.value
                                                    setDirInfo([...dirInfo, temp])}} />
                                                

</td><td style={{border: '0.5pt solid rgb(0, 0, 0)', verticalAlign: 'top', padding: '0pt 5.4pt', overflow: 'hidden', overflowWrap: 'break-word'}}>
    <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Address:</span></p>
                                                
                                                
                                                    <input type="text" value={dirInfo[1].dAddr} 
                                                onChange={(e)=>{ 
                                                    let temp = dirInfo; 
                                                    temp[1].dAddr = e.target.value
                                                    setDirInfo([...dirInfo, temp])}}  />
                                                

</td></tr>
<tr style={{height: '28.05pt'}}>
    <td style={{border: '0.5pt solid rgb(0, 0, 0)', verticalAlign: 'top', padding: '0pt 5.4pt', overflow: 'hidden', overflowWrap: 'break-word'}}>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Phone:</span></p>
                                                
                                                
                                                    <input type="text" value={dirInfo[0].dPhone} 
                                                onChange={(e)=>{ 
                                                    let temp = dirInfo; 
                                                    temp[0].dPhone = e.target.value
                                                    setDirInfo([...dirInfo, temp])}}  />
                                                

</td><td style={{border: '0.5pt solid rgb(0, 0, 0)', verticalAlign: 'top', padding: '0pt 5.4pt', overflow: 'hidden', overflowWrap: 'break-word'}}>
<p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Phone:</span></p>
                                                
                                                
                                                    <input type="text" value={dirInfo[1].dPhone} 
                                                onChange={(e)=>{ 
                                                    let temp = dirInfo; 
                                                    temp[1].dPhone = e.target.value
                                                    setDirInfo([...dirInfo, temp])}}  />
                                                

</td></tr>
<tr style={{height: '28.05pt'}}>
    <td style={{border: '0.5pt solid rgb(0, 0, 0)', verticalAlign: 'top', padding: '0pt 5.4pt', overflow: 'hidden', overflowWrap: 'break-word'}}>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Email:</span></p>
                                                
                                                
                                                    <input type="text" value={dirInfo[0].dEmail} 
                                                onChange={(e)=>{ 
                                                    let temp = dirInfo; 
                                                    temp[0].dEmail = e.target.value
                                                    if(pattern.test(e.target.value))
                                                        temp[0].dErrEmail = false 
                                                    else 
                                                        temp[0].dErrEmail = true
                                                    setDirInfo([...dirInfo, temp])}}  />
                                                    {
                                            dirInfo[0].dErrEmail ?
                                            <span style={{ color:'red' }}>Please Enter a valid Email</span>
                                            :   null
                                        }
                                                

</td><td style={{border: '0.5pt solid rgb(0, 0, 0)', verticalAlign: 'top', padding: '0pt 5.4pt', overflow: 'hidden', overflowWrap: 'break-word'}}>
    <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Email:</span></p>
                                                
                                                
                                                    <input type="text" value={dirInfo[1].dEmail} 
                                                onChange={(e)=>{ 
                                                    let temp = dirInfo; 
                                                    temp[1].dEmail = e.target.value
                                                    if(pattern.test(e.target.value))
                                                        temp[1].dErrEmail = false 
                                                    else 
                                                        temp[1].dErrEmail = true
                                                    
                                                    setDirInfo([...dirInfo, temp])}}  />
                                                {
                                            dirInfo[1].dErrEmail ?
                                            <span style={{ color:'red' }}>Please Enter a valid Email</span>
                                            :   null
                                        }

</td></tr>
</tbody>
</table>
<br/>
        <p dir="ltr" style={{lineHeight: '1.2', textAlign:' justify', marginTop: '8pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt',
fontFamily: 'Verdana',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Signed:
{   formObj.dirInfoSign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={formObj.dirInfoSign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> OpenSign('dirInfoSign')}
                                            >
                                                Add Signature
                                            </div>
                                            
                                                {/* <input type="text" value={formObj.dirInfoSign} onChange={(e)=> setFormObj({...formObj, dirInfoSign : e.target.value})}/> */}
                                            
                                            
(Print)</span><span style={{fontSize: '9pt',
fontFamily: 'Verdana',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}></span><span style={{fontSize: '9pt',
fontFamily: 'Verdana',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Name:
                                            
                                            
                                                <input type="text" value={formObj.dirInfoName} onChange={(e)=> setFormObj({...formObj, dirInfoName : e.target.value})} />
                                            

</span></p><p dir="ltr" style={{lineHeight: '1.2', textAlign:' justify', marginTop: '8pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt',
fontFamily: 'Verdana',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Date:
                                            
                                            
                                                <DateTimeInput
                                                    dateSetFn={setDirInfoDate}
                                                    dateValue={dirInfoDate}
                                                />
                                            

</span></p><br />

                            <p dir="ltr" style={{lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>By signing this CREDIT APPLICATION FORM,&nbsp;</span></p>
                            <ul style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
                            <li dir="ltr" aria-level="1" style={{listStyleType: 'disc',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-0.5499999999999972pt'}}>
                            <p dir="ltr" role="presentation" style={{lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>I permit that all information provided in this application form is correct.</span></p>
                            </li>
                            <li dir="ltr" aria-level="1" style={{listStyleType: 'disc',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-0.5499999999999972pt'}}>
                            <p dir="ltr" role="presentation" style={{lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt'}}>
                            <span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                            I certify that I am authorised to sign this application for and on behalf of all directors/partners owners of the Applicant in my capacity as&nbsp;<input type="text" value={formObj.dirInfoPos} onChange={(e)=> setFormObj({...formObj, dirInfoPos : e.target.value})} /> (Position Held ).</span>
                            </p>
                            </li>
                            <li dir="ltr" aria-level="1" style={{listStyleType: 'disc',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-0.5499999999999972pt'}}>
                            <p dir="ltr" role="presentation" style={{lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>I certify that I have read, understood, and agreed to all Terms and Conditions stated below as part of this credit application.&nbsp;</span></p>
                            </li>
                            </ul>
                            <br />
                           
                            <ol start="3" style={{marginTop: '0px',
marginBottom: '0px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'upper-roman',
fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.2', marginTop: '6pt', marginBottom: '12pt'}}><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>ENVIRO WASTE SERVICES GROUP FINAL APPROVAL</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Office Use Only:</span></p>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}>
    <span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Have the Credit References checked out?&nbsp; &nbsp; &nbsp; &nbsp; YES-<input type="checkbox" checked={credRef} onChange={(e)=>setCredRef(!credRef)} /></span>
    <span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}></span>
    <span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}></span>
    <span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp; &nbsp; &nbsp;NO-&nbsp;<input type="checkbox" checked={!credRef} onChange={(e)=>setCredRef(!credRef)} /></span></p>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Checked by:
<input type="text" value={formObj.checkedBy} onChange={(e)=> setFormObj({...formObj, checkedBy : e.target.value})} /><br/>
Date: 
<DateTimeInput
                                        dateSetFn={setCheckedByDate}
                                        dateValue={checkedByDate}
                                    />
</span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Type of account approved: </span><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}></span><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>&nbsp; &nbsp; &nbsp;Cash on Delivery-<input type="checkbox" checked={codAcc} onChange={(e)=>setCodAcc(!codAcc)} /></span>
    <span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}></span><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}></span><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>&nbsp; &nbsp; &nbsp;Account-<input type="checkbox" checked={!codAcc} onChange={(e)=>setCodAcc(!codAcc)} /></span><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp;</span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Application is being approved.&nbsp; &nbsp; YES-<input type="checkbox" checked={applAppr} onChange={(e)=>setApplAppr(!applAppr)} /></span>
    <span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}></span><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}></span>
    <span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>&nbsp; &nbsp; &nbsp;NO-<input type="checkbox" checked={!applAppr} onChange={(e)=>setApplAppr(!applAppr)} /></span></p>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>If application is rejected, please state the reason(s):</span></p>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>
                            
                            
                                <input type="text" value={formObj.envRejectRes} onChange={(e)=>setFormObj({...formObj, envRejectRes : e.target.value})}/>
                            

                            </span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Final Approval</span></p>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Name:
                                
                                
                                    <input type="text" value={formObj.finApprName} onChange={(e)=>setFormObj({...formObj, finApprName : e.target.value})}/>
                                &nbsp;<div style={{ display:'flex', flexDirection:'row'}}>

Date:
                            
                                
                                    <DateTimeInput
                                        dateSetFn={setFinApprDate}
                                        dateValue={finApprDate}
                                    />
</div>&nbsp;Position:

                                
                                    <input type="text"  value={formObj.finApprPos} onChange={(e)=>setFormObj({...formObj, finApprPos : e.target.value})}/>
                                

</span></p><br /><p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '11pt',fontFamily: 'Arial',color: 'rgb(0 0 0)',backgroundColor: 'transparent',fontWeight: '400',fontStyle: 'normal',fontVariantLigatures: 'normal',fontVariantCaps: 'normal',fontVariantEastAsian: 'normal',fontVariantPosition: 'normal',textDecoration: 'none',verticalAlign: 'baseline',whiteSpace: 'pre-wrap',}}>Signature:&nbsp;

{   formObj.finApprSign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={formObj.finApprSign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> OpenSign('finApprSign')}
                                            >
                                                Add Signature
                                            </div>    
                                {/* <input type="text" value={formObj.finApprSign} onChange={(e)=>setFormObj({...formObj, finApprSign : e.target.value})}/> */}
                            

                            </span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>GENERAL CREDIT TERMS</span></p><br />
        <ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Payment:</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}> The Customer must pay for all Products &amp; Services supplied by Enviro Waste Services Group Pty Ltd within any credit period granted in writing by Enviro Waste Services Group Pty Ltd.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>(a) Interest</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>: Enviro Waste Services Group Pty Ltd is entitled to charge the Customer interest on amounts not paid within the specified credit period at a rate equivalent to the reference rate charged by Enviro Waste Services Group Pty Ltd principal debt collectors, from invoice date until payment of the debt.</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.2', marginLeft: '36pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>(b) Should it become necessary for Enviro Waste Services Group Pty Ltd to institute legal action for recovery of any amounts due to it by the Customer, the Customer specifically acknowledges and agrees that it shall be liable to Enviro Waste Services Group Pty Ltd on demand for all costs incurred by Enviro Waste Services Group Pty Ltd in recovery of such amounts, including all costs on a full indemnity basis.</span></p>
        <ol start="3" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>GST</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>: Each amount payable by the Customer under these Terms in respect of a Taxable Supply by Enviro Waste Services Group Pty Ltd is a GST exclusive amount and on receipt of a tax invoice the Customer must, in addition to the amount and at the same time, pay the GST payable in respect of that supply. “Taxable Supply”, and “GST” have the meanings set out in the A New Tax System (Goods and Services) Act 1999.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Withdrawal</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}> or Variation of Credit: Enviro Waste Services Group Pty Ltd may at any time, without the need to provide a reason, vary or withdraw any credit granted to the Customer.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Disputes:</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}> Disputed invoices must be notified to Enviro Waste Services Group Pty Ltd within 14 days of delivery of the invoice by Enviro Waste Services Group Pty Ltd to the Customer, failing which Enviro Waste Services Group Pty Ltd will have no further liability in relation to the matters in dispute.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Enviro Waste Services Group Pty Ltd</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}> will rely on the information the Customer provides in respect of the Site. The Customer must ensure that there is unobstructed and safe access for Enviro Waste Services Group Pty Ltd to perform the services. If through no fault of Enviro Waste Services Group Pty Ltd, Enviro Waste Services Group Pty Ltd is delayed or experiences obstruction on Site, a charge of Enviro Waste Services Group Pty Ltd minimum charge applies.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>The Customer warrants and agrees</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}> that the ground surfaces traversed by Enviro Waste Services Group Pty Ltd service vehicles in connection with the performance of the Services will at all times be suitable for that purpose. The Customer indemnifies Enviro Waste Services Group Pty Ltd from and against any liabilities, claims, damages, actions, costs and expenses as a result of or arising out of any breach by the Customer of any of the provisions and warranties set out in this clause.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>The Customer is solely responsible</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}> for obtaining permission from relevant person, entity or authority (i.e Council, Body Corporate/Strata Manager) in which the circumstances requires the permission of a third party.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>The Customer is obligated</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}> to supply the product as described in the Quote issued by Enviro Waste Services Group Pty Ltd representatives, any variations to product description must be authorised in writing by an Enviro Waste Services Group Pty Ltd representative prior to job completion. The Customer is obligated to supply a MSDS (Material Safety Data Sheet) upon request. If Customer is unable to provide a MSDS (Material Safety Data Sheet), Enviro Waste Services Group Pty Ltd may at anytime perform an Analysis at an accredited laboratory on the Customer’s product to determine the acceptance criteria. The cost for Analysis will be at the Customer’s expense.&nbsp;&nbsp;</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '18pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>I/We agree to be bound by the General Credit Terms set out above and by the terms set out in this application and warrant that the information given by me/us in this application is true and accurate.</span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '18pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Name:
        
                    
                        <input type="text" value={formObj.genCredName} onChange={(e)=>setFormObj({...formObj, genCredName : e.target.value})}/>
                    

                    Position:

                    
                        <input type="text" value={formObj.genCredPos} onChange={(e)=>setFormObj({...formObj, genCredPos : e.target.value})} />
                    

                    </span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '18pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Signature:
                    
                    {   formObj.genCredSign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={formObj.genCredSign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> OpenSign('genCredSign')}
                                            >
                                                Add Signature
                                            </div>    
   
                        {/* <input type="text" value={formObj.genCredSign} onChange={(e)=>setFormObj({...formObj, genCredSign : e.target.value})}/> */}
                    <br/>

                Date:
                    
                        <DateTimeInput
                            dateSetFn={setGenCredDate}
                            dateValue={genCredDate}
                        />
                    
                
                    </span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '18pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWweight: '400', fontStyle: 'italic', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>(Signed for and on behalf of the Customer)</span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>CREDIT AND PRIVACY INFORMATION</span></p><br />
        <ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>I/We consent and authorise Enviro Waste Services Group Pty Ltd</span></p>
            </li>
        </ol>
        <ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>To request a credit report containing information about my/our consumer or commercial credit arrangements from a credit agency for the purposes of assessing this application or in connection with the attached Guarantee and indemnity.&nbsp;</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>To give a credit reporting agency information to allow the credit reporting agency to create and maintain a credit information file containing information about me/us.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>To disclose a credit report or any personal information derived from the credit report, and any information about my/our personal or commercial credit arrangements to Enviro Waste Services Group Pty Ltd, a related body corporate of Enviro Waste Services Group Pty Ltd, any agent of Enviro Waste Services Group Pty Ltd assisting in processing the application and any other provider of credit to me/us named in this application in a credit report from a credit reporting agency; and</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>To notify and exchange information with other credit providers and any collection agent of Enviro Waste Services Group Pty Ltd or any of its bodies corporate.</span></p>
            </li>
        </ol>
        <ol start="2" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>I/We understand that the information permitted to be disclosed under the Privacy Act 1988 (Cth) includes:</span></p>
            </li>
        </ol>
        <ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Details to identify me/us – that is name, sex, date of birth, current and 2 previous addresses, current or last known employer, and driver’s licence number.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>The fact that I/we have applied for credit, the amount, or that Enviro Waste Services Group Pty Ltd is a current credit provider to me/us.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Advice that payments previously notified as unpaid are no longer overdue.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Payments overdue for at least 60 days and for which collection action has started.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Cheques for more than $100 drawn by me/us which have been dishonored more than once.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>In specified circumstances, that in the opinion of Enviro Waste Services Group Pty Ltd I/we have committed a serious credit infringement; and&nbsp;</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>The fact that credit provided to me/us by Enviro Waste Services Group Pty Ltd has been paid or otherwise discharged.</span></p>
            </li>
        </ol><br />
        <p dir="ltr" style={{lineHeight: '1.295', textIndent: '18pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Name: 
                    
                    
                        <input type="text" value={formObj.credPirName} onChange={(e)=>setFormObj({...formObj, credPirName : e.target.value})}/>
                    

                Position:
                    
                        <input type="text"  value={formObj.credPirPos} onChange={(e)=>setFormObj({...formObj, credPirPos : e.target.value})}/>
                    

                    </span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '18pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Signature:
                    {   formObj.credPirSign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={formObj.credPirSign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> OpenSign('credPirSign')}
                                            >
                                                Add Signature
                                            </div>
                    
                        {/* <input type="text" value={formObj.credPirSign} onChange={(e)=>setFormObj({...formObj, credPirSign : e.target.value})}/> */}
                    <br/>

                Date:
                    <div>
                        <DateTimeInput
                            dateSetFn={setCredPirDate}
                            dateValue={credPirDate}
                        />
                    </div>

                    </span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '18pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWweight: '400', fontStyle: 'italic', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>(Signed for and on behalf of the Customer)</span></p><br /><br /><br /><br /><br /><br /><br /><br />
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '12pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>GUARANTEE AND INDEMNITY</span></p>
        <ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Guarantee</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>The Guarantor unconditionally and irrevocably guarantees to Enviro Waste Services Group Pty Ltd the due and punctual payment of the Guaranteed Moneys and agrees:</span></p>
        <ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>On demand from time to time to pay an amount equal to the Guaranteed Moneys then due and payable.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Any statement signed by an Enviro Waste Services Group Pty Ltd director, secretary, credit manager or authoroised representative certifying the amount of Guaranteed Moneys or the money owing by the Guarantor under his Guarantee is, in the absence of manifest error, binding and conclusive on and against the Guarantor.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>This Guarantee is a continuing guarantee and remains in full force and effect until all the Guaranteed Moneys are paid or satisfied in full and is in addition to, and not prejudiced or affected by any other security or guarantee held by Enviro Waste Services Group Pty Ltd for the payment of Guaranteed Moneys.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>The liabilities of the Guarantor and the rights of Enviro Waste Services Group Pty Ltd under this Guarantee are not affected by anything which might otherwise affect them at law or in equity; and</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>If any payment by the Guarantor under this Guarantee or the Customer is avoided, set aside, ordered to be refunded or reduced rendered unenforceable by any laws relating to bankruptcy, insolvency or liquidation, that payment will be taken not to have been made and Enviro Waste Services Group Pty Ltd is entitled to recover from the Guarantor the value of that payment as if that payment had never been made. This clause continues after this Guarantee is discharged.&nbsp;</span></p>
            </li>
        </ol><br />
        <ol start="2" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Indemnity&nbsp;</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>If the obligation of the Customer to pay the Guaranteed Moneys to Enviro Waste Services Group Pty Ltd is unenforceable for any reason, the Guarantor as a separate undertaking unconditionally and irrevocably indemnifies Enviro Waste Services Group Pty Ltd against any loss Enviro Waste Services Group Pty Ltd suffers as a result. Enviro Waste Services Group Pty Ltd need not incur any expense or make any payment before enforcing this right of indemnity.</span></p>
        <ol start="3" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Waiver</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>No failure or delay by Enviro Waste Services Group Pty Ltd to exercise any power, right or remedy under this Guarantee operates as a waiver, nor does any single or partial exercise of any power, right or remedy preclude any other or further exercise of that power, right or remedy. Enviro Waste Services Group Pty Ltd rights and remedies under this Guarantee are in addition to any rights and remedies arising at law.</span></p>
        <ol start="4" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Claim in Administration&nbsp;</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Until this Guarantee is released by Enviro Waste Services Group Pty Ltd, the Guarantor will not without Enviro Waste Services Group Pty Ltd consent, prove in any Administration of the Customer in competition with Enviro Waste Services Group Pty Ltd or any related body corporate of Enviro Waste Services Group Pty Ltd.</span></p>
        <ol start="5" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Continuing Guarantee&nbsp;</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Any guarantee shall remain in force so long as the Customer shall maintain an account with Enviro Waste Services Group Pty Ltd. All guarantees shall be continuing guarantees and will terminate only in writing from Enviro Waste Services Group Pty Ltd.</span></p>
        <ol start="6" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Application of Moneys Received</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>If Enviro Waste Services Group Pty Ltd receives or recovers money in respect of debts of the Customer or anyone else, Enviro Waste Services Group Pty Ltd may use it to pay off whichever part of those debts it chooses and does not have to apply it for the Guarantor’s benefit.</span></p><br />
        <ol start="7" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Change Over Guarantor’s Assets&nbsp;</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>As security for payment to Enviro Waste Services Group Pty Ltd of the Guaranteed Moneys and for its obligations generally under this Guarantee, the Guarantor charges in favour of Enviro Waste Services Group Pty Ltd the whole of the Guarantor’s undertaking, property and assets (including, without limitation, all of the Guarantor’s interests, both legal and beneficial, in freehold and leasehold land and any assets listed in this application as Security Interest Property) both current and later acquired. Enviro Waste Services Group Pty Ltd may register a caveat on title or otherwise take such action as it deems appropriate to protect or enforce its interest in the security granted.&nbsp;</span></p>
        <ol start="8" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Trusts</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>This document binds the Guarantor both personally and as trustee of any trust of which the Guarantor is a trustee.</span></p>
        <ol start="9" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Acknowledgement</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', textIndent: '36pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>The Guarantor acknowledges the Guarantor:</span></p>
        <ol style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Has entered into this agreement voluntarily.</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Has read and understood the nature and consequences of entering into this Guarantee;</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Has not signed this Guarantee on the basis of any representation of Enviro Waste Services Group Pty Ltd, its employees, agents or representatives or under the duress of any person;</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Is entitled to see independent legal advice before signing this Guarantee; and</span></p>
            </li>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'lower-alpha',
fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre',
marginLeft: '-21.8pt',}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Submits to the non-exclusive jurisdiction of the courts of the State in which this Guarantee is received by Enviro Waste Services Group Pty Ltd and that the law applying in that State is the proper law governing this Guarantee.</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>&nbsp;</span></p>
            </li>
        </ol>
        <ol start="10" style={{marginTop: '0px',
marginBottom: '0px',
paddingInlineStart: '48px'}}>
            <li dir="ltr" aria-level="1" style={{listStyleType: 'decimal', fontSize: '10pt', fontFamily: 'Arial', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: '400', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-21.8pt'}}>
                <p dir="ltr" role="presentation" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>Severance&nbsp;</span></p>
            </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Each Clause and subclause of this Guarantee is separate and independent. If any clause or subclause is found to be invalid or ineffective. The other clauses or subclauses or parts with not be adversely affected.</span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>I have read and understood this document. I have not relied on anything said to me by the Customer or Enviro Waste Services Group Pty Ltd as to what it means or what its effects may be.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>SIGNED by each Guarantor in the presence of the witnesses whose names appear below.</span></p><br />
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '18pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Guarantor’s Name:</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}></span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                    
                    
                        <input type="text" value={formObj.gur1Name} onChange={(e)=>setFormObj({...formObj, gur1Name : e.target.value})}/>
                    


                Gurantor's Signature:
                {   formObj.gut1Sign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={formObj.gut1Sign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> OpenSign('gut1Sign')}
                                            >
                                                Add Signature
                                            </div>
                        {/* <input type="text" value={formObj.gut1Sign} onChange={(e)=>setFormObj({...formObj, gut1Sign : e.target.value})} /> */}
                    

                    </span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '18pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Witness Name:
                    
                    
                        <input type="text" value={formObj.wit1Name} onChange={(e)=>setFormObj({...formObj, wit1Name : e.target.value})}/>
                    

                Witness Signature:
                {   formObj.wit1Sign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={formObj.wit1Sign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> OpenSign('wit1Sign')}
                                            >
                                                Add Signature
                                            </div>
                    
                        {/* <input type="text"  value={formObj.wit1Sign} onChange={(e)=>setFormObj({...formObj, wit1Sign : e.target.value})}/> */}
                    

                    </span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '18pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Witness Address:
                    
                    
                        <input type="text" value={formObj.wit1Addr} onChange={(e)=>setFormObj({...formObj, wit1Addr : e.target.value})}/>
                    

                    </span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '18pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Guarantor’s Name:</span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}></span><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>
                    
                    
                        <input type="text" value={formObj.gur2Name} onChange={(e)=>setFormObj({...formObj, gur2Name : e.target.value})}/>
                    

                    Gurantor's Signature:
                    {   formObj.gut2Sign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={formObj.gut2Sign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> OpenSign('gut2Sign')}
                                            >
                                                Add Signature
                                            </div>
                    
                        {/* <input type="text"  value={formObj.gut2Sign} onChange={(e)=>setFormObj({...formObj, gut2Sign : e.target.value})}/> */}
                    

                    </span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '18pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Witness Name: 
                    
                    
                        <input type="text" value={formObj.wit2Name} onChange={(e)=>setFormObj({...formObj, wit2Name : e.target.value})}/>
                    

                Witness Signature:
                {   formObj.wit2Sign!=='' ?
                                            <div style={{
                                                display:'flex', alignItems:"center", justifyContent:"center", width:'10vw', height:'5vh'
                                            }}>
                                                <img
                                                    src={formObj.wit2Sign}
                                                    style={{ width: '100%', height : '100%' }}
                                                    alt=""
                                                />
                                            </div>
                                            :   null
                                            }
                                            <div className='quote-selector-btn quote-update-active'
                                                onClick={()=> OpenSign('wit2Sign')}
                                            >
                                                Add Signature
                                            </div>
                    
                        {/* <input type="text"  value={formObj.wit2Sign} onChange={(e)=>setFormObj({...formObj, wit2Sign : e.target.value})}/> */}
                    
                
                    </span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginLeft: '14.2pt', marginTop: '0pt', marginBottom: '18pt'}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>Witness Address:
                    
                    
                        <input type="text" value={formObj.wit2Addr} onChange={(e)=>setFormObj({...formObj, wit2Addr : e.target.value})}/>
                    

                    </span></p>
        <p dir="ltr" style={{lineHeight: '1.295',
marginTop: '0pt',
marginBottom: '8pt',}}><span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '700',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap',}}>DATED </span>
        <span style={{fontSize: '10pt',
fontFamily: 'Arial',
color: 'rgb(0 0 0)',
backgroundColor: 'transparent',
fontWeight: '400',
fontStyle: 'normal',
fontVariantLigatures: 'normal',
fontVariantCaps: 'normal',
fontVariantEastAsian: 'normal',
fontVariantPosition: 'normal',
textDecoration: 'none',
verticalAlign: 'baseline',
whiteSpace: 'pre-wrap'}}>this &nbsp;&nbsp;&nbsp;
            <input type="text" id="dayOf" value={formObj.dayOf} 
                onChange={(e)=>{ 
                    if(e.target.value > 0 && e.target.value < 32)
                        if(e.target.value > 29 && formObj.monthOf === "February")
                            setFormObj({...formObj, dayOf   :   28 })    
                        else    if(e.target.value > 30 && 
                            (formObj.monthOf === "April" || formObj.monthOf === "June" || formObj.monthOf === "September" || formObj.monthOf === "November") )
                            setFormObj({...formObj, dayOf   :   30  })
                        else
                            setFormObj({...formObj, dayOf   :   e.target.value })
                }}/>
        day of &nbsp;&nbsp;&nbsp;
            <input type="text" list="monthOf" id="monthOf" value={formObj.monthOf} onChange={(e) =>
                setFormObj({...formObj, monthOf : e.target.value})}/>
        , 20
                <input type="text" value={formObj.yearOf} 
                    onChange={(e) => {
                        setFormObj({...formObj, yearOf  :   e.target.value})
                    }}
                />
        </span></p>
                    </div>
                    </div>
                                </Scrollbars>
                <div className="quote-info-action-btns" onClick={(e) => { e.preventDefault(); e.stopPropagation(); updateTemplateCode();}} >
                    <div className="accept-btn">
                    Save Changes
                    </div>
                </div>
            </form>
            <SignaturePadModal
                modalAction={setSignModal}
                modalStatus={signModal}
                imgFn={signFunc}
            />
        </div>
    );
}

export default FormModal;
