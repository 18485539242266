import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import storeMain from '../screens/common/reducer'


const appReducer = combineReducers({
    storeMain
})

const rootReducer = (state, action) => {
    if(action.type === 'USER_LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk))
)

export { store }