import React from 'react'
import './App.css';import AppRouter from './app/router'
import { Provider } from 'react-redux'
import { store } from './app/store/store'


function App () {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  )
}

export default App;
