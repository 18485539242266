import React from "react"
import {
  useLoadScript
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import { GOOGLE_API_KEY, HOME_LATITUDE, HOME_LONGITUDE } from "../../store/config";

const libraries = ["places"];

export function GoogleMapInit() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });

  return { status :  (loadError||!isLoaded) ? false : true , value : loadError ? 'Error' : !isLoaded ? 'Loading...' : null}
}

export function SearchGoogleAddress({ cls=null, setMarkers=null, inputFn=null, inputVal=null, inputPlaceHolder=null }) {
    
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => HOME_LATITUDE, lng: () => HOME_LONGITUDE },
        radius: 100 * 1000,
        //   components: 'country:in'
      },
    });
  
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  
    const handleInput = (e) => {
  
      setValue(e.target.value);
      if(inputFn!==null){
        inputFn(e.target.value)
      }
      console.log(e.target.value, status, data)
    };
  
    const handleSelect = async (address) => {
  
      setValue(address, false);
  
      if(inputFn!==null){
        inputFn(address)
      }
      clearSuggestions();
      console.log('\n Suggestions :\n',status, data,'\nData Entered :\n', address)
      
        let results = [];
        await getGeocode({ address }).then(
          res =>{ results = res } 
        ).catch(err => { 
          console.log(err);
        })
        console.log(results)
        let GeoLat = null;
        let GeoLng = null;
        if(results && results.length!==0){
        await getLatLng(results[0]).then(
          res => { 
            GeoLat = res.lat; 
            GeoLng = res.lng; }
        ).catch(err => { console.log(err); })
        console.log(GeoLat, GeoLng, results)
        
        if(GeoLat && GeoLng){
          if(setMarkers!==null)
          setMarkers([{
            lat: GeoLat,
            lng: GeoLng
          }]);
          }
        }
        
        // setButtonVal({val:error, type:'error'})
        
        // console.log("😱 Error: ", error);
      
    };
  
    return (
      <div className={cls===null ? "search" : cls.outerBox}>
        {console.log('VALUE',value)}
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            value={((value==='' || value===null)&&(inputVal!==null)) ? inputVal : value}
            onChange={handleInput}
            disabled={!ready}
            placeholder={inputPlaceHolder===null ? "Search customer location" : inputPlaceHolder}
            className={ cls===null ? "searchbar-input" : cls.innerBox}
          />
          <ComboboxPopover style={{ zIndex: 5000000 }}>
            <ComboboxList>
              {status === "OK" &&
                data.map(({ place_id, description }, key) =>{
                  return(
                  <ComboboxOption key={place_id} value={description}/>
                )})}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }
  
  