import { CircularProgress } from '@material-ui/core'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Scrollbar from 'react-custom-scrollbars'
import { useHistory, useParams } from 'react-router'
import { EnviroLogo } from '../../assets/images'
import { fetch_quote_infos, reject_quote } from '../common/action'
import Swal from 'sweetalert2'

function App({ fetch_quote_info, quote_info, reject_quote_fx }) {

    const { uuid } = useParams()
    const history = useHistory();

    useEffect(() => {
        const fetch_quote_informations = () => {
            const failed = () => {
                history.push('/')
            }
            fetch_quote_info(uuid, failed)
        }

        fetch_quote_informations()
    }, [uuid, fetch_quote_info, history])

    

    const rejectBtnClick = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to reject the quote",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF0000',
            cancelButtonColor: '#13CD6A',
            confirmButtonText: 'Yes, Reject it!'
        }).then((result) => {
            if (result.value) {

                const success = (msg) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Quote Rejected!',
                        showConfirmButton: false,
                        timer: 1500
                      })
                    history.push('/')
                }

                const failed = (msg) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: msg
                      })
                    history.push('/')
                }

                reject_quote_fx(uuid, success, failed)

            }
        })
    }

    if(quote_info.id) {

        let imgDmyUrl = 'https://www.freedigitalphotos.net/images/img/homepage/394230.jpg'
        // let mainHost =  'https://quote.envirowasteadmin.com.au'
     
        const { created_by, 
          //invoice_amount, 
          quote_file, attached_files, id, tab_type, status } = quote_info

        return (
        <div className="app-layout">
          <div className="navbar-layout">
            <img src={EnviroLogo} alt="" />
          </div>
          <Scrollbar style={{ height: '85vh' }}>
              <div className="quote-info-layout">
                <div className="quote-info-box">
                    <h3>Quote #{quote_info.job_card_id}</h3>
                    {/* <h5>Ref Code : #{quote_info.job_card_code}</h5> */}
                    <div className="quote-box-grid-files">
                      <div onClick={() => window.open( quote_file, "_blank")} className="quote-box-item-sp">
                        <p>Quote</p>
                      </div>

                      {
                        attached_files.map((item, key) => {
                          return (
                            <div  onClick={() => window.open( item.url, "_blank")}  key={key} className="quote-box-item"
                            style={{ wordWrap:'break-word', wordBreak:'break-all' }}>
                              <p>{item.name.length > 25 ? `${item.name.substring(0,15)}...${item.name.substring(item.name.length-4,item.name.length)}` : item.name}</p>
                            </div>
                          )
                        })
                      }
                    </div>
{/* 
                    <p className="invoice-amt-title">Invoice Amount : <span style={{ fontSize: 22 }}>{ invoice_amount === 'null' ? ' $ ' +0.0 : ' $ ' + invoice_amount }</span></p> */}

                    
                    <p className="invoice-amt-title">
                      Please complete the following documents to finalise the payment process by clicking "Fill and Accept" below
                    </p>

                    <div className="quote-info-main-layout">
                      <p>Quote Send By</p>
                      <div className="quote-sender-layout">
                        <img src={created_by.dp??imgDmyUrl} alt="" />
                        <div className="quote-sender-infos">
                          <p style={{ fontSize: 16, fontWeight: 'bold' }}>{created_by.name}</p>
                          <p style={{ fontSize: 11, textTransform:'capitalize' }}>{created_by.user_type}</p>
                          <p style={{ fontSize: 11, fontWeight: 'bold' }}>{created_by.contact_number}</p>
                        </div>
                      </div>
                    </div>
                      { status === "pending" ?
                    <div className="quote-info-action-btns">
                      
                      <button 
                        onClick={() => rejectBtnClick()} 
                        className="reject-btn">Reject</button>
                      <button 
                        onClick={() =>{
                          if(tab_type==='pumps')
                            history.push('/pumps-editor')
                          else
                            history.push('/waste-editor')
                        }} 
                        className="accept-btn">Fill & Accept</button>
                      
                    </div>
                    :
                    <div className="quote-info-action-btns">
                      { status === "rejected" ?
                      <button 
                        className="reject-btn">Quote Rejected</button> 
                        :
                      <button 
                        className="accept-btn">Quote Accepted</button>
                      }
                    </div>
                    }
                </div>
              </div>
          </Scrollbar>
        </div>
        )
      
    } else {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
                <CircularProgress />
            </div>
        )
    }

    
}

const mapStateProps = (state) => {
    const { quote_info } = state.storeMain
    return { quote_info }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_quote_info: (uuid, failed) => dispatch(fetch_quote_infos(uuid, failed)),
        reject_quote_fx: (uuid, success, failed) => dispatch(reject_quote(uuid, success, failed))
      }
}

export default connect(mapStateProps, mapDispatchToProps) (App)
