import axios from 'axios'
// import { store } from './store'

export default function request(type, url, params, success, failed){
    
    // let state = store.getState()
    // let token = state.userLogin.authToken
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

    switch (type) {
        case 'get':
            return axios.get(url)
                .then(response => {
                    console.log('Get Response', response)
                    if(success) {success(response.data)}
                    return response.data
                })
                .catch(error => {
                    console.log("Server Error", error)
                    console.log("server Response", error.response)
                    if(failed) {failed()}
                    return []
                })

        case 'post':
            return axios.post(url, params)
                .then(response => {
                    console.log('Post Response', response)
                    if(success) {success(response.data)}
                    return response.data
                })
                .catch(error => {
                    console.log("Server Error", error)
                    console.log("server Response", error.response)
                    if(failed) {failed()}
                    return error.response ? error.response.data : error
                })

        case 'post-form':
                return axios.post(url, params, {headers: {"Content-Type": "multipart/form-data"}})
                    .then(response => {
                        console.log('Post Response', response)
                        if(success) {success(response.data)}
                        return response.data
                    })
                    .catch(error => {
                        console.log("Server Error", error)
                        console.log("server Response", error.response)
                        if(failed) {failed()}
                        if(error.response) {
                            return error.response.data
                        } else return null;
                        
                    })

        case 'patch':
            return axios.patch(url + '/' + params)
                .then(response => {
                    console.log('Patch Response', response)
                    if(success) {success()}
                    return response.data
                })
                .catch(error => {
                    console.log("Server Error", error)
                    console.log("server Response", error.response)
                    if(failed) {failed()}
                    return error.response.data
                })

        case 'put':
            return axios.put(url, params, {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    console.log('Put Response', response)
                    if(success) {success(response)}
                    return response
                })
                // .catch(error => {
                //     console.log("Server Error", error)
                //     console.log("server Response", error.response)
                //     if(failed) {failed()}
                //     if(error.response) {
                //         return error.response.data
                //     } else {
                //         return error
                //     }
                // })

        case 'patch-form':
            return axios.patch(url, params, {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    console.log('Patch Response', response)
                    if(success) {success()}
                    return response.data
                })
                .catch(error => {
                    console.log("Server Error", error)
                    console.log("server Response", error.response)
                    if(failed) {failed()}
                    return error.response.data
                })

        case 'delete':
            return axios.delete(url)
                .then(response => {
                    console.log('Delete Response', response)
                    if(success) {success(response.data)}
                    return response.data
                })
                .catch(error => {
                    console.log("Server Error", error)
                    console.log("server Response", error.response)
                    if(failed) {failed()}
                    return error.response ? error.response.data : error
                })

        default: 
            break;
    }
}