import React, {useState} from 'react';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

function DateTimeInput ({dateValue, dateSetFn, clsName, acceptFunc=null, pickerType="", openEdit=false, viewsOnly=""}) {

  const [openCal, setOpenCal] = useState(openEdit)

  const LNTextField = props => {
    return (
      <input
        type="text"
        value={props.value}
        style={{ fontSize: '12px', border:'none', display:'flex', alignItems:'center', margin:'0 5px', justifyContent:'center' }}
        className={clsName}
        onClick={() =>{ setOpenCal(!openCal) }}
      />
    );
  };

  const renderInput = props => <LNTextField value={props.value} label={props.lable} />

  // console.log(dateValue, typeof dateValue)
  const [dateStr, setDateStr] = useState(dateValue ? dateValue : new Date())
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {
        pickerType==="" ?
      <DatePicker 
        value={dateStr} 
        views={ viewsOnly==="MY" ? ["month","year"] : viewsOnly==="DM" ? ["date","month"] : viewsOnly==="DY" ? ["date","year"] : ["year","month","date"] }
        readOnly={dateSetFn===""? true: false} 
        onChange={(e) => { dateSetFn(dayjs(e).format( viewsOnly==="MY" ? "YYYY-MM" : viewsOnly==="DM" ? "MM-DD" : viewsOnly==="DY" ? "YYYY-DD" : 'YYYY-MM-DD')); setDateStr(e); }}
        onAccept={(e) => { if(acceptFunc){ acceptFunc(e); } setOpenCal(false); }}
        onClose={()=> setOpenCal(false)}
        format={ viewsOnly==="MY" ? "MM-yyyy" : viewsOnly==="DM" ? "dd-MM" : viewsOnly==="DY" ? "dd-yyyy" : "dd-MM-yyyy"}
        open={openCal}
        TextFieldComponent={renderInput}
      />
      :
        pickerType==="dateTime" ?
      <DateTimePicker
        value={dateStr} 
      className={clsName}
      readOnly={dateSetFn===""? true: false} 
      onChange={(e) => { dateSetFn(dayjs(e).format('YYYY-MM-DD')); setDateStr(dayjs(e).format('DD-MM-YYYY hh:mm')); }}
      onAccept={(e) => { if(acceptFunc){ acceptFunc(e); } setOpenCal(false); }}
      onClose={()=> setOpenCal(false)}
      open={openCal}
      TextFieldComponent={renderInput}
      format={"dd-MM-yyyy hh:mm"}
      />
      :
      <TimePicker
      value={dateStr} 
      className={clsName}
      readOnly={dateSetFn===""? true: false} 
      onChange={(e) => { dateSetFn(dayjs(e).format('hh:mm:ss')); setDateStr(dayjs(e).format('hh:mm:ss')); }}
      onAccept={(e) => { if(acceptFunc){ acceptFunc(e); } setOpenCal(false); }}
      onClose={()=> setOpenCal(false)}
      open={openCal}
      TextFieldComponent={renderInput}
      format={"hh:mm:ss"}
      />
      }
    </MuiPickersUtilsProvider>
  );
}

export default DateTimeInput;
